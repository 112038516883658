import { Action, Category } from '../../Types';
import { LOAD_INITIAL } from '../actionTypes';

type CategoryInitialState = {
  categories: Category[];
  categoriesRaw: Category[];
};

const initialState: CategoryInitialState = {
  categories: [],
  categoriesRaw: [],
};

const createNestedCategories = (categories: Category[], parentId: string | null): Category[] => {
  return categories
    .filter((category) => category.parentId === parentId)
    .map((categoryParent) => {
      return {
        ...categoryParent,
        categories: createNestedCategories(categories, categoryParent.categoryId),
      };
    });
};

const createNestedParents = (
  categories: Category[],
  parents: Category[],
  parentId?: string | null,
): Category[] => {
  const parent = categories.find((category) => category.categoryId === parentId);
  if (parent) {
    parents.unshift(parent);
    return createNestedParents(categories, parents, parent?.parentId);
  }

  return parents;
};

const mapNestedParents = (categories: Category[]): Category[] => {
  return categories.map((category) => {
    const parents = createNestedParents(categories, [], category.parentId);
    const level = parents.length + 1;
    const titleFull = [...parents.map((parent) => parent.title), category.title].join(' / ');
    const slugFull = [...parents.map((parent) => parent.slug), category.slug].join('/');

    return {
      ...category,
      parents,
      level,
      titleFull,
      slugFull,
    };
  });
};

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case LOAD_INITIAL:
      return {
        categories: createNestedCategories([...action.payload.categories], null),
        categoriesRaw: mapNestedParents([...action.payload.categories]),
      };
    default:
      return state;
  }
}
