import React from 'react';
import PropTypes from 'prop-types';
import { ucWordsLocale } from '../../services/util/Title';

interface TitleProps {
  text: string;
}

export default function Title({ text }: TitleProps) {
  return <>{ucWordsLocale(text)}</>;
}

Title.propTypes = {
  text: PropTypes.string,
};

Title.defaultProps = {
  text: '',
};
