import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaShoppingBag } from 'react-icons/fa';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap-v5';
import { useHistory, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { cartCombineCreate, notificationPush, signInModalShow } from '../../services/store/actions';
import { favoriteCombineCreateSrv, favoriteCombineDeleteSrv } from '../../services/Favorite';
import { selectAuthenticated, selectFavoriteCombine } from '../../services/store/selectors';
import { useWindowSize } from '../../services/util/WindowSize';
import { CartCombine, Combine } from '../../services/Types';
import Product from '../product/Product';
import Title from '../format/Title';
import Currency from '../format/Currency';
import { DetailMore } from '../search/DetailMore';
import ImageList from '../search/ImageList';
import './CombineDetail.scss';

interface CombineDetailProps {
  combine: Combine;
  combineVariantId?: string;
  layout: 'page' | 'modal';
}

export default function CombineDetail({ combine, combineVariantId, layout }: CombineDetailProps) {
  const { combineId } = combine;

  console.log(layout);

  const dispatch = useDispatch();
  const device = useWindowSize();
  const history = useHistory();

  const authenticated = useSelector(selectAuthenticated);
  const favorites = useSelector(selectFavoriteCombine);
  const favorite = favorites && favorites.includes(combineId);

  const [selectedCombineVariantId, setSelectedCombineVariantId] = useState<string>('');

  useEffect(() => {
    if (combineVariantId) {
      setSelectedCombineVariantId(combineVariantId);
    } else {
      setSelectedCombineVariantId(combine.variants[0].combineVariantId);
    }
  }, [combineVariantId]);

  const handleFavorite = async () => {
    if (!authenticated) {
      dispatch(signInModalShow());
    } else if (favorite) {
      favoriteCombineDeleteSrv(combine.combineId);
    } else {
      favoriteCombineCreateSrv(combine.combineId);
    }
  };

  const handleAddToBasket = (cartCombine: CartCombine) => {
    let priceOriginal = 0;
    let priceFinal = 0;
    cartCombine.products.forEach((cartCombineProduct) => {
      const variant = combine.products
        .find((product) => product.productId === cartCombineProduct.productId)
        ?.product.variants.find((variant) => {
          return (
            variant.color.colorId === cartCombineProduct.colorId &&
            variant.size.sizeId === cartCombineProduct.sizeId
          );
        });
      if (variant) {
        priceOriginal += variant.priceOriginal;
        priceFinal += variant.priceFinal;
      }
    });

    dispatch(
      cartCombineCreate({
        combineId: cartCombine.combineId,
        combineVariantId: cartCombine.combineVariantId,
        amount: 1,
        products: cartCombine.products.map((cartCombineProduct) => {
          return {
            productId: cartCombineProduct.productId,
            colorId: cartCombineProduct.colorId,
            sizeId: cartCombineProduct.sizeId,
          };
        }),
        priceOriginal,
        priceFinal,
        discount: combine.discount,
      }),
    );

    dispatch(
      notificationPush({
        type: 'success',
        text: 'Kombin sepetinize eklendi.',
      }),
    );

    history.push('/sepetim');
  };

  const handleClose = () => {
    history.goBack();
  };

  const productsVariant = combine.products.filter(
    (product) => product.combineVariantId === selectedCombineVariantId,
  );

  const imagesVariant = combine.images.filter(
    (image) => image.combineVariantId === selectedCombineVariantId,
  );

  const schema = yup.object().shape({
    combineId: yup.string().required(),
    combineVariantId: yup.string().required(),
    products: yup
      .array()
      .min(1, 'En az 1 ürün seçilmelidir')
      .of(
        yup.object().shape({
          productId: yup.string().required('Ürün seçilmelidir'),
          colorId: yup.string().when('deleted', {
            is: (deleted: boolean) => !deleted,
            then: yup.string().required('Renk seçilmelidir'),
          }),
          sizeId: yup.string().when('deleted', {
            is: (deleted: boolean) => !deleted,
            then: yup.string().required('Beden seçilmelidir'),
          }),
          deleted: yup.boolean().required(),
        }),
      ),
  });

  const initialValues = {
    combineId: combine.combineId,
    combineVariantId: selectedCombineVariantId,
    products: productsVariant.map((product) => {
      return {
        productId: product.product.productId,
        colorId: product.colorId,
        sizeId: '',
        deleted: false,
      };
    }),
  };

  const { values, touched, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      handleAddToBasket(values);
    },
  });

  let combinePrice = 0;
  values.products.forEach((cartCombineProduct) => {
    const variant = combine.products
      .find((product) => product.productId === cartCombineProduct.productId)
      ?.product.variants.find((variant) => {
        return (
          variant.color.colorId === cartCombineProduct.colorId &&
          variant.size.sizeId === cartCombineProduct.sizeId
        );
      });
    if (variant) {
      combinePrice += variant.priceFinal;
    }
  });

  const detailMore = (
    <div className="combine-detail-more">
      <h2>Komb.in Açıklaması</h2>
      <ul>
        <li>
          15 gün içinde ücretsiz iade edebilirsiniz. Detaylı bilgi için
          <span> </span>
          <Link to="/yardim/iade">tıklayın</Link>.
        </li>
        <li>
          Kombin indirimi, ürün indirimlerine ekstra indirimdir. İndirimden faydalanmak için
          kombindeki tüm ürünleri satın almanız gerekir.
        </li>
        <li>Ürün fiyatları mağaza tarafından belirlenmektedir.</li>
        {combine.merchant ? (
          <li>
            Bu kombin
            <span> </span>
            <Link to={`/magaza/${combine.merchant.slug}`}>{combine.merchant.title}</Link>
            <span> </span>
            tarafından gönderilecektir.
          </li>
        ) : null}
      </ul>
      {combine.detail ? (
        <>
          <h2>Mağaza Açıklaması</h2>
          <DetailMore detail={combine.detail} />
        </>
      ) : null}
    </div>
  );

  const detail = (
    <>
      <div className="combine-detail-wrapper">
        <div className="combine-image-wrapper">
          {device.isDesktop ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`favorite-combine-${combineId}`}>
                  {favorite ? 'Favorilerden Çıkar' : 'Favorilere Ekle'}
                </Tooltip>
              }
            >
              <div
                className={`combine-favorite ${favorite ? 'combine-favorite-active' : ''}`}
                role="presentation"
                onClick={handleFavorite}
              >
                {favorite ? <AiFillHeart /> : <AiOutlineHeart />}
              </div>
            </OverlayTrigger>
          ) : (
            <div
              className={`combine-favorite ${favorite ? 'combine-favorite-active' : ''}`}
              role="presentation"
              onClick={handleFavorite}
            >
              {favorite ? <AiFillHeart /> : <AiOutlineHeart />}
            </div>
          )}

          <ImageList images={imagesVariant} />

          {device.isDesktop ? detailMore : null}
        </div>
        <div className="combine-detail">
          <h2 className="combine-style">{combine.style.title}</h2>
          <h1 className="combine-title">
            <Title text={combine.title} />
          </h1>
          {combine.merchant ? (
            <p>
              Mağaza:
              <span> </span>
              <Link to={`/magaza/${combine.merchant.slug}`}>{combine.merchant.title}</Link>
            </p>
          ) : null}

          <div className="combine-products">
            {values.products.map((cartCombineProduct, cartCombineProductIndex) => {
              const product = productsVariant.find(
                (product) => product.product.productId === cartCombineProduct.productId,
              );

              if (product) {
                return (
                  <div
                    className={`combine-product ${
                      cartCombineProduct.deleted ? 'cart-product-deleted' : ''
                    }`}
                    key={product.productId}
                  >
                    <Product
                      product={product.product}
                      layout="horizontal"
                      showPurchaseOptions={false}
                      defaultColorId={cartCombineProduct.colorId}
                      defaultSizeId={cartCombineProduct.sizeId}
                      onSelectColorId={(colorId: string) =>
                        setFieldValue(`products.${cartCombineProductIndex}.colorId`, colorId)
                      }
                      onSelectSizeId={(sizeId: string) =>
                        setFieldValue(`products.${cartCombineProductIndex}.sizeId`, sizeId)
                      }
                    />

                    {touched &&
                    touched.products &&
                    errors &&
                    errors.products &&
                    errors.products[cartCombineProductIndex] ? (
                      <div className="combine-warning">Beden seçilmelidir </div>
                    ) : null}
                  </div>
                );
              }
              return null;
            })}
          </div>

          {touched &&
          touched.products &&
          values &&
          values.products &&
          values.products.filter((product) => !product.deleted).length < 2 ? (
            <div className="combine-warning">Kombin&apos;de en az 2 ürün olmalıdır </div>
          ) : null}

          {device.isDesktop ? (
            <>
              {combine.discount > 0 ? (
                <p className="combine-discount">Kombin İndirimi: -{combine.discount}%</p>
              ) : null}
              <p>
                Kombin Tutarı:
                <span> </span>
                {combine.discount > 0 ? (
                  <>
                    <span className="combine-price-initial">
                      <Currency price={combinePrice} />
                    </span>
                    <span> </span>
                  </>
                ) : null}
                <span className="combine-price-final">
                  <Currency price={(combinePrice * (100 - combine.discount)) / 100} />
                </span>
              </p>

              <div className="combine-add-to-cart">
                <button className="btn btn-kombin" type="button" onClick={() => handleSubmit()}>
                  <FaShoppingBag /> Sepetime Ekle
                </button>
              </div>
            </>
          ) : null}
        </div>

        {device.isMobile ? detailMore : null}
      </div>
    </>
  );

  return (
    <>
      {device.isDesktop ? (
        <>{detail}</>
      ) : (
        <Modal show onHide={handleClose} fullscreen>
          <Modal.Body className="modal-combine">{detail}</Modal.Body>
          <Modal.Footer className="modal-combine-footer">
            {history.length > 2 ? (
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
            ) : null}

            <span style={{ flex: 1 }} />

            <Currency price={combinePrice} />
            <span> </span>
            <button className="btn btn-kombin" type="button" onClick={() => handleSubmit()}>
              <FaShoppingBag />
              <span> </span>
              Sepetime Ekle
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
