import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { AiOutlineClose } from 'react-icons/ai';
import { MdArrowBack } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import CombineDetail from './CombineDetail';
import { latestVisitCombineCreate } from '../../services/store/actions';
import { Combine } from '../../services/Types';
import { useWindowSize } from '../../services/util/WindowSize';
import './CombineModal.scss';

type CombineModalProps = {
  combine: Combine;
  combineVariantId?: string;
  show: boolean;
  onDialogCancel: any;
};

export default function CombineModal({
  combine,
  combineVariantId,
  show,
  onDialogCancel,
}: CombineModalProps) {
  const handleClose = () => onDialogCancel();
  const dispatch = useDispatch();
  const device = useWindowSize();

  useEffect(() => {
    if (show) {
      dispatch(latestVisitCombineCreate(combine.combineId));
    }
  }, [show]);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="xl" fullscreen="lg-down">
        {device.isDesktop ? (
          <Modal.Header>
            <Modal.Title>{combine.title}</Modal.Title>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => handleClose()}
            >
              <AiOutlineClose />
            </button>
          </Modal.Header>
        ) : null}
        <Modal.Body className="modal-combine">
          {device.isMobile ? (
            <button type="button" className="btn btn-lg btn-back" onClick={() => handleClose()}>
              <MdArrowBack />
            </button>
          ) : null}
          <CombineDetail layout="modal" combine={combine} combineVariantId={combineVariantId} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => handleClose()}
          >
            Kapat
          </button>

          {/*
          <div className="modal-combine-footer">
            <Counter min={1} max={100} value={0} />
            <button className="btn btn-kombin" type="button">
              <FaShoppingBag /> Sepetime Ekle
            </button>
          </div>
          */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
