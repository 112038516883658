import { gql } from '@apollo/client';
import { client } from './Api';
import { SearchOptions, SearchParams } from './Types';

interface LoadProductProps {
  filters: SearchParams;
  options: SearchOptions;
}

interface LoadProductFragmentsByTypeProps {
  filters: SearchParams;
}

export const loadProducts = async ({ filters, options }: LoadProductProps) => {
  try {
    const result: any = await client.query({
      query: gql`
        query products(
          $keyword: String
          $departmentIds: [ID!]
          $brandIds: [ID!]
          $categoryIds: [ID!]
          $merchantIds: [ID!]
          $productIds: [ID!]
          $colorIds: [ID!]
          $styleIds: [ID!]
          $sizeIds: [ID!]
          $rates: [Int!]
          $priceMin: Float
          $priceMax: Float
          $productIdsNot: [ID!]
          $sortBy: String
          $limit: Int
          $offset: Int
          $isActive: Boolean
          $isApproved: Boolean
          $isArchived: Boolean
          $isInStock: Boolean
          $isActiveMerchant: Boolean
        ) {
          products(
            filters: {
              keyword: $keyword
              departmentIds: $departmentIds
              brandIds: $brandIds
              categoryIds: $categoryIds
              merchantIds: $merchantIds
              productIds: $productIds
              colorIds: $colorIds
              styleIds: $styleIds
              sizeIds: $sizeIds
              rates: $rates
              productIdsNot: $productIdsNot
              priceMin: $priceMin
              priceMax: $priceMax
              isActive: $isActive
              isApproved: $isApproved
              isArchived: $isArchived
              isInStock: $isInStock
              isActiveMerchant: $isActiveMerchant
            }
            sortBy: $sortBy
            limit: $limit
            offset: $offset
          ) {
            productId
            title
            detail
            rate
            slug
            isActive
            isApproved
            isArchived
            isActiveMerchant
            categoryId
            category {
              categoryId
              slug
              title
            }
            departmentId
            department {
              departmentId
              slug
              title
            }
            brandId
            brand {
              brandId
              slug
              title
            }
            merchantId
            merchant {
              merchantId
              title
              slug
            }
            images {
              imageId
              colorId
              color {
                title
                colorId
                code
              }
              image {
                imageId
                path
              }
            }
            variants {
              stock
              sizeId
              size {
                sizeId
                title
              }
              colorId
              color {
                title
                colorId
                code
                slug
              }
              barcode
              priceFinal
              priceOriginal
            }
          }
        }
      `,
      variables: {
        ...filters,
        ...options,
      },
      fetchPolicy: 'no-cache',
    });

    return result.data.products;
  } catch (e) {
    console.log(e);
    // store.dispatch(
    //   notificationPush({
    //     type: 'danger',
    //     text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    //   }),
    // );
  }

  return false;
};

export const loadProduct = async (productId: string) => {
  try {
    const result: any = await client.query({
      query: gql`
        query product($productId: ID!) {
          product(productId: $productId) {
            productId
            title
            detail
            rate
            slug
            categoryId
            category {
              categoryId
              slug
              title
            }
            departmentId
            department {
              departmentId
              slug
              title
            }
            brandId
            brand {
              brandId
              slug
              title
            }
            merchantId
            merchant {
              merchantId
              title
              slug
            }
            images {
              imageId
              colorId
              color {
                title
                colorId
                code
              }
              image {
                imageId
                path
              }
            }
            variants {
              stock
              sizeId
              size {
                sizeId
                title
              }
              colorId
              color {
                title
                colorId
                code
                slug
              }
              barcode
              priceFinal
              priceOriginal
            }
          }
        }
      `,
      variables: {
        productId,
      },

      // fetchPolicy: 'no-cache',
    });

    return result.data.product;
  } catch (e) {
    console.log(e);
    // store.dispatch(
    //   notificationPush({
    //     type: 'danger',
    //     text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    //   }),
    // );
  }

  return false;
};

export const loadProductFragmentsByType = async ({ filters }: LoadProductFragmentsByTypeProps) => {
  try {
    const result: any = await client.query({
      query: gql`
        query productFragmentsByType(
          $keyword: String
          $departmentIds: [ID!]
          $brandIds: [ID!]
          $categoryIds: [ID!]
          $merchantIds: [ID!]
          $colorIds: [ID!]
          $styleIds: [ID!]
          $sizeIds: [ID!]
          $rates: [Int!]
          $priceMin: Float
          $priceMax: Float
        ) {
          productFragmentsByType(
            filters: {
              keyword: $keyword
              departmentIds: $departmentIds
              brandIds: $brandIds
              categoryIds: $categoryIds
              merchantIds: $merchantIds
              colorIds: $colorIds
              styleIds: $styleIds
              sizeIds: $sizeIds
              rates: $rates
              priceMin: $priceMin
              priceMax: $priceMax
              isActive: true
              isApproved: true
              isArchived: false
              isInStock: true
              isActiveMerchant: true
            }
          ) {
            priceMin
            priceMax

            departments {
              count
              departmentId
            }

            brands {
              count
              brandId
            }

            merchants {
              count
              merchantId
            }

            categories {
              count
              categoryId
            }

            colors {
              count
              colorId
            }

            styles {
              count
              styleId
            }

            rates {
              count
              rate
            }
          }
        }
      `,
      variables: {
        ...filters,
      },
      fetchPolicy: 'no-cache',
    });

    return result.data.productFragmentsByType;
  } catch (e) {
    console.log(e);
    // store.dispatch(
    //   notificationPush({
    //     type: 'danger',
    //     text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    //   }),
    // );
  }

  return false;
};
