import { Action, Notification } from '../../Types';
import { NOTIFICATION_CLEAR_ALL, NOTIFICATION_CLEAR_ONE, NOTIFICATION_PUSH } from '../actionTypes';

const idGenerate = () => {
  return Math.random().toString(36).substr(2, 6);
};

const initialState: Notification[] = [];

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case NOTIFICATION_PUSH:
      return [...state, { ...action.payload, id: idGenerate() }];
    case NOTIFICATION_CLEAR_ONE:
      return state.filter((item) => item.id !== action.payload.notificationId);
    case NOTIFICATION_CLEAR_ALL:
      return [];
    default:
      return state;
  }
}
