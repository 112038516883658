import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';
import Currency from '../format/Currency';
import { Combine as ICombine, CombineVariant } from '../../services/Types';
import { signInModalShow } from '../../services/store/actions';
import * as RouteGenerator from '../../services/route/RouteGenerator';
import { favoriteCombineCreateSrv, favoriteCombineDeleteSrv } from '../../services/Favorite';
import { imageUrl } from '../../services/Image';
import { selectAuthenticated, selectFavoriteCombine } from '../../services/store/selectors';
import { useWindowSize } from '../../services/util/WindowSize';
import getElementOffset from '../../services/util/ElementOffset';
import CombineModal from './CombineModal';
import Title from '../format/Title';
import './Combine.scss';

interface CombineProps {
  combine: ICombine;
  combineVariantId?: string;
  layout?: 'horizontal' | 'vertical';
}

export default function Combine({ combine, combineVariantId, layout }: CombineProps) {
  const { combineId, title, slug } = combine;

  const dispatch = useDispatch();
  const device = useWindowSize();
  const authenticated = useSelector(selectAuthenticated);
  const favorites = useSelector(selectFavoriteCombine);
  const favorite = favorites && favorites.includes(combineId);

  const [imageIndex, setImageIndex] = useState(0);
  const [quickRender, setQuickRender] = useState(false);
  const [quickShow, setQuickShow] = useState(false);

  const productImageRef = useRef(null);

  const [selectedCombineVariantId, setSelectedCombineVariantId] = useState<string>(
    combine.variants[0].combineVariantId,
  );
  const [selectedCombineVariant, setSelectedCombineVariant] = useState<CombineVariant>(
    combine.variants[0],
  );

  useEffect(() => {
    if (combineVariantId) {
      const combineVariant = combine.variants.find(
        (variant) => variant.combineVariantId === combineVariantId,
      );

      if (combineVariant) {
        setSelectedCombineVariantId(combineVariantId);
        setSelectedCombineVariant(combineVariant);
      }
    } else {
      setSelectedCombineVariantId(combine.variants[0].combineVariantId);
      setSelectedCombineVariant(combine.variants[0]);
    }
  }, [combineVariantId]);

  const handleMouseLeave = () => {
    setImageIndex(0);
  };

  const handleMouseMove = (event: any) => {
    if (productImageRef.current && event) {
      const offset = getElementOffset(productImageRef.current);

      const imageIndex = Math.floor(
        // @ts-ignore
        ((event.pageX - offset.left) / offset.width) * images.length,
      );

      setImageIndex(imageIndex);
    }
  };

  const handleFavorite = async () => {
    if (!authenticated) {
      dispatch(signInModalShow());
    } else if (favorite) {
      favoriteCombineDeleteSrv(combineId);
    } else {
      favoriteCombineCreateSrv(combineId);
    }
  };

  const handleShowQuick = () => {
    setQuickShow(true);
    setQuickRender(true);
  };

  const images = combine.images.filter(
    (image) => image.combineVariantId === selectedCombineVariantId,
  );

  const products = combine.products.filter(
    (product) => product.combineVariantId === selectedCombineVariantId,
  );

  const priceMin = products?.reduce(
    (price, product) =>
      price +
      product.product?.variants.reduce((variantPriceMin, variant) => {
        if (variant.priceFinal < variantPriceMin) {
          return variant.priceFinal;
        }
        return variantPriceMin;
      }, Number.POSITIVE_INFINITY),
    0,
  );

  const priceMax = products?.reduce(
    (price, product) =>
      price +
      product.product?.variants.reduce((variantPriceMax, variant) => {
        if (variant.priceFinal > variantPriceMax) {
          return variant.priceFinal;
        }
        return variantPriceMax;
      }, 0),
    0,
  );

  return (
    <>
      <div
        className={`combine ${layout ? `combine-${layout}` : 'combine-vertical'}`}
        role="presentation"
      >
        {device.isDesktop ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`favorite-combine-${combineId}`}>
                {favorite ? 'Favorilerden Çıkar' : 'Favorilere Ekle'}
              </Tooltip>
            }
          >
            <div
              className={`combine-favorite ${favorite ? 'combine-favorite-active' : ''}`}
              role="presentation"
              onClick={handleFavorite}
            >
              {favorite ? <AiFillHeart /> : <AiOutlineHeart />}
            </div>
          </OverlayTrigger>
        ) : (
          <div
            className={`combine-favorite ${favorite ? 'combine-favorite-active' : ''}`}
            role="presentation"
            onClick={handleFavorite}
          >
            {favorite ? <AiFillHeart /> : <AiOutlineHeart />}
          </div>
        )}

        <div
          className="combine-image-wrapper"
          ref={productImageRef}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <Link
            to={RouteGenerator.combineDetail(
              combineId,
              slug,
              combine.style.styleId,
              combine.style.slug,
              selectedCombineVariantId,
              selectedCombineVariant?.slug,
            )}
            className="combine-image"
          >
            {images.map((image, index) => {
              const img = (
                <img
                  className={index === imageIndex ? 'active' : ''}
                  src={imageUrl(image.image.path, 'list')}
                  alt=""
                />
              );

              return <React.Fragment key={image.image.imageId}>{img}</React.Fragment>;
            })}
          </Link>

          {/*
          {images.length > 1 ? (
            <div className="combine-image-variants">
              {images.map((image, index) => (
                <LazyLoad height={450} offset={200}>
                  <div key={image.image.imageId} className={index === imageIndex ? 'active' : ''} />
                </LazyLoad>
              ))}
            </div>
          ) : null}
          */}

          <div className="combine-quick">
            <button
              type="button"
              className="btn btn-sm btn-kombin"
              onClick={() => handleShowQuick()}
            >
              Hızlı Görüntüle
            </button>
          </div>
        </div>

        <div className="combine-detail">
          <div className="combine-style">{combine.style.title}</div>
          <div className="combine-title">
            <Title text={title} />
          </div>

          {/*
          {rate >= 1 ? (
            <div className="combine-rate">
              <Rate rate={rate} />
            </div>
          ) : null}
          */}

          <div className="combine-price">
            {priceMin !== priceMax ? (
              <>
                <span className="combine-price-min">
                  <Currency price={(priceMin * (100 - combine.discount)) / 100} />
                </span>
                <span>&nbsp;-&nbsp;</span>
              </>
            ) : null}
            <span className="combine-price-max">
              <Currency price={(priceMax * (100 - combine.discount)) / 100} />
            </span>
            {combine.discount > 0 ? (
              <span className="combine-discount">-{combine.discount}%</span>
            ) : null}
          </div>

          {combine.variants.length > 1 ? (
            <>
              {layout === 'horizontal' ? (
                <div className="combine-option-title">
                  <strong>Varyant: </strong>
                </div>
              ) : null}
              <div className="combine-variant">
                <ul>
                  {combine.variants.map((variant, index) => {
                    if (index <= 4) {
                      const image = combine.images.find(
                        (image) => image.combineVariantId === variant.combineVariantId,
                      );
                      if (image) {
                        return (
                          <li
                            key={variant.combineVariantId}
                            role="presentation"
                            className={
                              selectedCombineVariant.combineVariantId === variant.combineVariantId
                                ? 'combine-variant-active'
                                : ''
                            }
                            onClick={() => {
                              setSelectedCombineVariant(variant);
                              setSelectedCombineVariantId(variant.combineVariantId);
                            }}
                          >
                            <div>
                              <img src={imageUrl(image.image.path, 'option')} alt="" />
                            </div>
                          </li>
                        );
                      }
                    }
                    return null;
                  })}
                  {combine.variants.length > 4 ? (
                    <li
                      role="presentation"
                      className="combine-variant-more"
                      onClick={() => handleShowQuick()}
                    >
                      <div>+{combine.variants.length - 4}</div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </>
          ) : null}
        </div>
      </div>

      {quickRender ? (
        <CombineModal
          combine={combine}
          show={quickShow}
          onDialogCancel={() => setQuickShow(false)}
        />
      ) : null}
    </>
  );
}
