import React from 'react';
import PropTypes from 'prop-types';

interface PercentProps {
  original: number;
  final: number;
}

export default function Percent({ original, final }: PercentProps) {
  const percent = (((original - final) / original) * 100).toFixed(0);
  return <>{percent}%</>;
}

Percent.propTypes = {
  original: PropTypes.number,
  final: PropTypes.number,
};

Percent.defaultProps = {
  original: null,
  final: null,
};
