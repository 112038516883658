import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCartCount, selectFavoriteCount } from '../../services/store/selectors';
import './FooterMobile.scss';

export default function FooterMobile() {
  const location = useLocation();
  const headerCartCount = useSelector(selectCartCount);
  const headerFavoriteCount = useSelector(selectFavoriteCount);

  let active;
  if (location.pathname.startsWith('/hesabim/favorilerim')) {
    active = 'favorite';
  } else if (location.pathname.startsWith('/hesabim')) {
    active = 'account';
  } else if (
    location.pathname.startsWith('/giris-yap') ||
    location.pathname.startsWith('/kayit-ol') ||
    location.pathname.startsWith('/sifremi-unuttum') ||
    location.pathname.startsWith('/sifremi-unuttum-dogrulama')
  ) {
    active = 'account';
  } else if (location.pathname.startsWith('/kombin')) {
    active = 'combine';
  } else if (
    location.pathname.startsWith('/urunler') ||
    location.pathname.startsWith('/erkek/') ||
    location.pathname.startsWith('/kadin/') ||
    location.pathname.startsWith('/cocuk/erkek') ||
    location.pathname.startsWith('/cocuk/kiz') ||
    location.pathname.startsWith('/bebek/erkek') ||
    location.pathname.startsWith('/bebek/kiz')
  ) {
    active = 'combine';
  } else if (location.pathname.startsWith('/yardim')) {
    active = 'account';
  } else if (location.pathname === '/sepetim') {
    active = 'cart';
  } else if (location.pathname === '/') {
    active = 'home';
  } else if (location.pathname.startsWith('/siparis-odeme/')) {
    active = 'cart';
  }

  return (
    <>
      <footer className="footer-bottom">
        <ul>
          <li>
            <Link to="/" className={active === 'home' ? 'active' : ''}>
              {active === 'home' ? (
                <span className="material-icons">home</span>
              ) : (
                <span className="material-icons-outlined">home</span>
              )}
              <span>Kampanyalar</span>
            </Link>
          </li>
          <li>
            <Link to="/kombin/erkek" className={active === 'combine' ? 'active' : ''}>
              {active === 'combine' ? (
                <span className="material-icons">checkroom</span>
              ) : (
                <span className="material-icons-outlined">checkroom</span>
              )}
              <span>Kombinler</span>
            </Link>
          </li>
          <li>
            <Link to="/hesabim/favorilerim" className={active === 'favorite' ? 'active' : ''}>
              {active === 'favorite' ? (
                <span className="material-icons">favorite</span>
              ) : (
                <span className="material-icons-outlined">favorite_border</span>
              )}
              <span>Favorilerim</span>
              {headerFavoriteCount > 0 ? (
                <sup className="count-favorite">{headerFavoriteCount}</sup>
              ) : null}
            </Link>
          </li>
          <li>
            <Link to="/sepetim" className={active === 'cart' ? 'active' : ''}>
              {active === 'cart' ? (
                <span className="material-icons">shopping_cart</span>
              ) : (
                <span className="material-icons-outlined">shopping_cart</span>
              )}
              <span>Sepetim</span>
              {headerCartCount > 0 ? <sup>{headerCartCount}</sup> : null}
            </Link>
          </li>
          <li>
            <Link to="/hesabim" className={active === 'account' ? 'active' : ''}>
              {active === 'account' ? (
                <span className="material-icons">person</span>
              ) : (
                <span className="material-icons-outlined">person</span>
              )}
              <span>Hesabım</span>
            </Link>
          </li>
        </ul>
      </footer>
    </>
  );
}
