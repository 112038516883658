import { useEffect, useState } from 'react';

type WindowSize = {
  width: number;
  height: number;
  widthContainer: number;
  device: string;
  isMobile: boolean | null;
  isDesktop: boolean | null;
};

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    widthContainer: 0,
    device: '',
    isMobile: null,
    isDesktop: null,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      const device = window.innerWidth >= 992 ? 'desktop' : 'mobile';
      const container = window.document.getElementById("kombin-customer")?.firstChild;

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        // @ts-ignore
        widthContainer: container?.scrollWidth,
        device,
        isMobile: device === 'mobile',
        isDesktop: device === 'desktop',
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
