import { gql } from '@apollo/client';
import { client } from './Api';
import { SearchOptions, SearchParams } from './Types';

interface LoadCombineProps {
  filters: SearchParams;
  options: SearchOptions;
}

interface LoadCombineFragmentsByTypeProps {
  filters: SearchParams;
}

export const loadCombines = async ({ filters, options }: LoadCombineProps) => {
  try {
    const result: any = await client.query({
      query: gql`
        query combines(
          $keyword: String
          $departmentIds: [ID!]
          $brandIds: [ID!]
          $categoryIds: [ID!]
          $merchantIds: [ID!]
          $colorIds: [ID!]
          $styleIds: [ID!]
          $sizeIds: [ID!]
          $rates: [Int!]
          $priceMin: Float
          $priceMax: Float
          $combineIds: [ID!]
          $sortBy: String
          $limit: Int
          $offset: Int
          $isActive: Boolean
          $isApproved: Boolean
          $isArchived: Boolean
          $isInStock: Boolean
          $isActiveMerchant: Boolean
        ) {
          combines(
            filters: {
              keyword: $keyword
              departmentIds: $departmentIds
              brandIds: $brandIds
              categoryIds: $categoryIds
              merchantIds: $merchantIds
              colorIds: $colorIds
              styleIds: $styleIds
              sizeIds: $sizeIds
              rates: $rates
              priceMin: $priceMin
              priceMax: $priceMax
              combineIds: $combineIds
              isActive: $isActive
              isApproved: $isApproved
              isArchived: $isArchived
              isInStock: $isInStock
              isActiveMerchant: $isActiveMerchant
            }
            sortBy: $sortBy
            limit: $limit
            offset: $offset
          ) {
            combineId
            title
            detail
            slug
            isActive
            isApproved
            isArchived
            isActiveMerchant
            discount
            rate
            department {
              departmentId
              title
              slug
            }
            style {
              styleId
              title
              slug
            }
            merchantId
            merchant {
              merchantId
              title
              slug
            }
            variants {
              combineVariantId
              title
              slug
            }
            images {
              combineVariantId
              image {
                imageId
                path
              }
            }
            products {
              combineVariantId
              productId
              colorId
              product {
                productId
                title
                detail
                rate
                slug
                category {
                  categoryId
                  slug
                  title
                }
                brand {
                  brandId
                  slug
                  title
                }
                merchant {
                  merchantId
                  slug
                  title
                }
                images {
                  imageId
                  colorId
                  color {
                    title
                    colorId
                    code
                  }
                  image {
                    imageId
                    path
                  }
                }
                variants {
                  stock
                  sizeId
                  size {
                    sizeId
                    title
                  }
                  colorId
                  color {
                    title
                    colorId
                    code
                    slug
                  }
                  barcode
                  priceFinal
                  priceOriginal
                }
              }
            }
          }
        }
      `,
      variables: {
        ...filters,
        ...options,
      },
      fetchPolicy: 'no-cache',
    });

    return result.data.combines;
  } catch (e) {
    console.log(e);
    // store.dispatch(
    //   notificationPush({
    //     type: 'danger',
    //     text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    //   }),
    // );
  }

  return false;
};

export const loadCombine = async (combineId: string) => {
  try {
    const result: any = await client.query({
      query: gql`
        query combines($combineId: ID!) {
          combine(combineId: $combineId) {
            combineId
            title
            detail
            slug
            isActive
            isApproved
            isArchived
            isActiveMerchant
            discount
            rate
            department {
              departmentId
              title
              slug
            }
            style {
              styleId
              title
              slug
            }
            merchantId
            merchant {
              merchantId
              title
              slug
            }
            variants {
              combineVariantId
              title
              slug
            }
            images {
              combineVariantId
              image {
                imageId
                path
              }
            }
            products {
              combineVariantId
              productId
              colorId
              product {
                productId
                title
                detail
                rate
                slug
                category {
                  categoryId
                  slug
                  title
                }
                brand {
                  brandId
                  slug
                  title
                }
                merchant {
                  merchantId
                  title
                }
                images {
                  imageId
                  colorId
                  color {
                    title
                    colorId
                    code
                  }
                  image {
                    imageId
                    path
                  }
                }
                variants {
                  stock
                  sizeId
                  size {
                    sizeId
                    title
                  }
                  colorId
                  color {
                    title
                    colorId
                    code
                    slug
                  }
                  barcode
                  priceFinal
                  priceOriginal
                }
              }
            }
          }
        }
      `,
      variables: {
        combineId,
      },
      // fetchPolicy: 'no-cache',
    });

    return result.data.combine;
  } catch (e) {
    console.log(e);
    // store.dispatch(
    //   notificationPush({
    //     type: 'danger',
    //     text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    //   }),
    // );
  }

  return false;
};

export const loadCombineFragmentsByType = async ({ filters }: LoadCombineFragmentsByTypeProps) => {
  try {
    const result: any = await client.query({
      query: gql`
        query combineFragmentsByType(
          $keyword: String
          $departmentIds: [ID!]
          $brandIds: [ID!]
          $categoryIds: [ID!]
          $merchantIds: [ID!]
          $colorIds: [ID!]
          $styleIds: [ID!]
          $sizeIds: [ID!]
          $rates: [Int!]
          $priceMin: Float
          $priceMax: Float
        ) {
          combineFragmentsByType(
            filters: {
              keyword: $keyword
              departmentIds: $departmentIds
              brandIds: $brandIds
              categoryIds: $categoryIds
              merchantIds: $merchantIds
              colorIds: $colorIds
              styleIds: $styleIds
              sizeIds: $sizeIds
              rates: $rates
              priceMin: $priceMin
              priceMax: $priceMax
              isActive: true
              isApproved: true
              isArchived: false
              isInStock: true
              isActiveMerchant: true
            }
          ) {
            priceMin
            priceMax

            departments {
              count
              departmentId
            }

            brands {
              count
              brandId
            }

            merchants {
              count
              merchantId
            }

            categories {
              count
              categoryId
            }

            colors {
              count
              colorId
            }

            styles {
              count
              styleId
            }

            rates {
              count
              rate
            }
          }
        }
      `,
      variables: {
        ...filters,
      },
      fetchPolicy: 'no-cache',
    });

    return result.data.combineFragmentsByType;
  } catch (e) {
    console.log(e);
    // store.dispatch(
    //   notificationPush({
    //     type: 'danger',
    //     text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    //   }),
    // );
  }

  return false;
};
