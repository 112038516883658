import React from 'react';
import NavbarDesktop from './NavbarDesktop';
import { useWindowSize } from '../../services/util/WindowSize';
import './Navbar.scss';

export default function Navbar() {
  const device = useWindowSize();

  return <>{device.isDesktop ? <NavbarDesktop /> : null}</>;
}
