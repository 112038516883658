import React, { useState } from 'react';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import { Product as IProduct } from '../../services/Types';
import Product from './Product';
import './ProductListHorizontal.scss';
import { useWindowSize } from '../../services/util/WindowSize';

interface ProductListHorizontalProps {
  products: IProduct[];
}

export default function ProductListHorizontal({ products }: ProductListHorizontalProps) {
  const device = useWindowSize();
  const productsCountVisible = device.isMobile ? 2 : 5;

  const [navigateIndex, setNavigationIndex] = useState(0);

  const navigate = (index: number) => {
    const element = document.querySelector('.product-list-horizontal');

    if (element) {
      element.scroll(element.clientWidth / productsCountVisible * index, 0);
    }
  };

  const scroll = () => {
    const element = document.querySelector('.product-list-horizontal');

    if (element) {
      const index = Math.floor((element.scrollLeft / element.clientWidth) * 6);
      setNavigationIndex(index);
    }
  };

  const navigatePrev = () => {
    // console.log(navigateIndex);
    navigate(navigateIndex - productsCountVisible);
  };

  const navigateNext = () => {
    navigate(navigateIndex + productsCountVisible);
  };

  return (
    <>
      <div className="product-list-wrapper">
        <div className="product-list-horizontal" onScrollCapture={scroll}>
          {products.map((product) => (
            <Product key={product.productId} product={product} />
          ))}
        </div>
        {device.isDesktop && products.length > 5 ? (
          <>
            {navigateIndex > 0 ? (
              <div
                className="product-list-horizontal-prev"
                onClick={navigatePrev}
                role="presentation"
              >
                <AiOutlineArrowLeft />
              </div>
            ) : null}

            {navigateIndex < products.length - productsCountVisible ? (
              <div
                className="product-list-horizontal-next"
                onClick={navigateNext}
                role="presentation"
              >
                <AiOutlineArrowRight />
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}
