import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap-v5';
import { useSelector } from 'react-redux';

import MarketingEmailForm from './MarketingEmailForm';
import { selectAuthenticated } from '../../services/store/selectors';

const MarketingEmailModal = () => {
  const [renderModal, setRenderModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const authenticated = useSelector(selectAuthenticated);

  const handleHideModal = () => {
    localStorage.setItem('requestedNotShowAgain', 'true');
    setShowModal(false);
  };

  // CHECK LOCAL STATE/REDUX -> LOGGED IN? SUBMITED PREVIOUSLY? ASKED NOT SHOW AGAIN?
  // ############################
  useEffect(() => {
    const previouslySubmitted = localStorage.getItem('marketingEmailSubmitted');
    const requestedNotShowAgain = localStorage.getItem('requestedNotShowAgain');

    if (!(authenticated || previouslySubmitted === 'true' || requestedNotShowAgain === 'true')) {
      const durationDelay = 100 * 60 * 1000; // 5 minutes in milliseconds
      setTimeout(() => {
        // Check again since they might have filled form or logged in before pop up shows
        if (
          !(
            authenticated ||
            localStorage.getItem('marketingEmailSubmitted') === 'true' ||
            localStorage.getItem('requestedNotShowAgain') === 'true'
          )
        ) {
          setRenderModal(true);
          setShowModal(true);
        }
      }, durationDelay);
    }
  }, []);

  return (
    <>
      {renderModal ? (
        <Modal animation centered size="xl" show={showModal} onHide={handleHideModal}>
          <Modal.Body className="d-flex align-items-center p-0">
            {/*
            <div className="rounded-start d-none d-lg-inline-block">
              <img
                className="rounded-start"
                src="https://place-hold.it/300x300"
                style={{ margin: '-0.5px' }}
                width="100%"
                alt=""
              />
            </div>
            */}
            <Button
              variant="close"
              onClick={handleHideModal}
              style={{ position: 'absolute', right: '0', top: '0' }}
              className="m-2"
            />
            <Button
              variant="light"
              onClick={() => {
                localStorage.setItem('requestedNotShowAgain', 'true');
                handleHideModal();
              }}
              style={{ position: 'absolute', right: '0', bottom: '0' }}
              className="m-2 mt-3"
            >
              Bir daha gösterme
            </Button>
            <div
              className="flex-grow-1 px-3 p-4 d-flex flex-column align-items-center justify-content-center"
              style={{ minHeight: '250px' }}
            >
              <h2 className="text-center">Ücretsiz Kataloğumuza Kayıt Olun</h2>
              <MarketingEmailForm target="popup" onHide={handleHideModal} />
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default MarketingEmailModal;
