import { Action } from '../../Types';
import { LOAD_INITIAL, LOAD_INITIAL_USER } from '../actionTypes';

interface InitialProp {
  loadingData: boolean;
  loadingUser: boolean;
  loadedData: boolean;
  loadedUser: boolean;
}

const initialState: InitialProp = {
  loadingData: true,
  loadingUser: true,
  loadedData: false,
  loadedUser: false,
};

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case LOAD_INITIAL:
      return {
        ...state,
        loadedData: true,
        loadingData: false,
      };
    case LOAD_INITIAL_USER:
      return {
        ...state,
        loadedUser: true,
        loadingUser: false,
      };
    default:
      return state;
  }
}
