import React from 'react';
import { useSelector } from 'react-redux';
import { Notification as INotification } from '../../services/Types';
import { RootState } from '../../services/store/store';
import Notification from './Notification';
import { useWindowSize } from '../../services/util/WindowSize';

export default function Notifications() {
  const notifications: INotification[] = useSelector((store: RootState) => store.notification);
  const device = useWindowSize();

  return (
    <>
      {notifications && notifications.length > 0 ? (
        <div
          className={`
            toast-container position-fixed 
            p-3
            ${device.isDesktop ? 'bottom-0 end-0' : 'top-0 start-50 translate-middle-x'}
          `}
          style={{ zIndex: 10000 }}
        >
          {notifications.map((notification: INotification) => (
            <Notification
              id={notification.id}
              text={notification.text}
              type={notification.type}
              key={notification.id}
            />
          ))}
        </div>
      ) : null}
    </>
  );
}
