import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import ReactPixel from 'react-facebook-pixel';
// import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LogRocket from 'logrocket';
import {
  selectAuthenticated,
  selectCookieWarning,
  selectUser,
} from '../../services/store/selectors';
import { User } from '../../services/Types';

export default function Meta() {
  const authenticated = useSelector(selectAuthenticated);
  const user: User = useSelector(selectUser);
  const cookieWarning = useSelector(selectCookieWarning);
  const history = useHistory();

  useEffect(() => {
    async function areFontsReady() {
      await (document as any).fonts.ready;
      document.body.classList.add('fonts-loaded');
    }

    areFontsReady();
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      setTimeout(() => {
        LogRocket.init('ofmi0d/kombin');
      }, 10 * 1000);
    }
  }, []);

  useEffect(() => {
    if (authenticated && user && user.userId) {
      // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      let advancedMatching = {};

      if (user) {
        let gender = '';
        if (user.genderId === '1') {
          gender = 'f';
        }
        if (user.genderId === '2') {
          gender = 'm';
        }

        let phone = '';
        if (user.phone) {
          phone = user.phone.replace(/\+\s/, '');
        }

        let birthDate = '';
        if (user.dateBirthday) {
          birthDate = moment(user.dateBirthday).format('YYYYMMDD');
        }

        let city = '';
        if (user.addressDefault && user.addressDefault.location1.name) {
          city = user.addressDefault.location1.name.toLowerCase();
        }

        let zipCode = '';
        if (user.addressDefault && user.addressDefault.zipCode) {
          zipCode = user.addressDefault.zipCode.toLowerCase();
        }

        advancedMatching = {
          // External ID
          external_id: user.email,

          // Email
          em: user.email,

          // First Name
          fn: user.nameFirst,

          // Last Name
          ln: user.nameLast,

          // Country
          country: 'tr',

          // City
          ct: city,

          // State
          st: '',

          // Zip or Postal Code
          zp: zipCode,

          // Birthdate
          db: birthDate,

          // Gender
          ge: gender,

          // Phone
          ph: phone,
        };
      }

      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: true, // enable logs
      };

      // @ts-ignore
      ReactPixel.init('1532495260420682', advancedMatching, options);
      // ReactGA.set({
      //   userId: user.userId,
      // });

      if (process.env.NODE_ENV !== 'development') {
        LogRocket.identify(user.email, {
          name: `${user.nameFirst} ${user.nameLast}`,
          email: user.email,
        });
      }
    }

    if (authenticated === false && cookieWarning) {
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: true, // enable logs
      };

      // @ts-ignore
      ReactPixel.init('1532495260420682', {}, options);
    }
  }, [authenticated]);

  useEffect(() => {
    history.listen(() => {
      ReactPixel.pageView(); // For tracking page view
      // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
      // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
      // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
      // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.

      // console.log(location);
      // ReactGA.pageview(location.pathname + location.search);
    });
  }, [history]);

  return (
    <Helmet defaultTitle="Komb.in" titleTemplate="%s | Komb.in">
      <meta charSet="utf-8" />
      <base href="/" target="_blank" />
      <meta
        name="copyright"
        content="PixelPerfect Yazılım ve İnternet Hizmetleri Limited Şirketi"
      />
      <meta
        name="keywords"
        content="kombin, moda, stil, sanal deneme kabini, gardrop yönetimi, moda ve stil asistanı"
      />
      <meta name="robots" content="index,follow" />
      <meta property="og:locale" content="tr" />
      <meta property="og:site_name" content="Komb.in" />
      <meta name="facebook-domain-verification" content="sv9pqykb7cxgltfu251yukm1qaldt4" />
    </Helmet>
  );
}
