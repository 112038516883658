import { Link } from 'react-router-dom';
import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPinterest, FaTwitter } from 'react-icons/fa';
import MarketingEmailForm from '../marketing/MarketingEmailForm';
import './FooterDesktop.scss';

export default function FooterDesktop() {
  const isIPad =
    navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);

  const socials = [
    {
      text: 'Instagram',
      icon: <FaInstagram />,
      link: 'https://www.instagram.com/komb.in_/',
    },
    {
      text: 'Facebook',
      icon: <FaFacebookF />,
      link: 'https://www.facebook.com/komb.in',
    },
    {
      text: 'Pinterest',
      icon: <FaPinterest />,
      link: 'https://pinterest.com/komb_in',
    },
    {
      text: 'Twitter',
      icon: <FaTwitter />,
      link: 'https://twitter.com/komb_in',
    },
    {
      text: 'Linked In',
      icon: <FaLinkedinIn />,
      link: 'https://www.linkedin.com/company/kombin/',
    },
  ];

  return (
    <>
      <footer className="footer-detail">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="col-icon">
              <span className="material-icons-outlined">local_shipping</span>
            </div>
            <div className="col-content">
              <h3>ÜCRETSİZ KARGO</h3>
              <p>100TL üzeri alışverişlerde ücretsiz kargo. </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="col-icon">
              <span className="material-icons-outlined">support_agent</span>
            </div>
            <div className="col-content">
              <h3>24/7 DESTEK</h3>
              <p>Bizimle haftanın her günü, günün her saati iletişime geçin. </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="col-icon">
              <span className="material-icons-outlined">settings_backup_restore</span>
            </div>
            <div className="col-content">
              <h3>15 GÜN ÜCRETSİZ İADE</h3>
              <p>Beklediğiniz gibi olmayan ürünleri ücretsiz iade edin.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="col-icon">
              <span className="material-icons-outlined">gpp_good</span>
            </div>
            <div className="col-content">
              <h3>100% GÜVENLİ ALIŞVERİŞ</h3>
              <p>Komb.in SSL güvenlik sertifikası kullanmaktadır.</p>
            </div>
          </div>
        </div>
      </footer>

      {!isIPad ? (
        <footer className="footer-more">
          <div className="footer-more-top">
            <div className="col-about">
              <h4>
                <img src="/img/logo/logo.svg" alt="komb.in" height={20} />
              </h4>
              <ul>
                <li>
                  <Link to="/biz-kimiz">Hakkımızda</Link>
                </li>
                <li>
                  <Link to="/kullanici-sozlesmesi">Kullanıcı Sözleşmesi</Link>
                </li>
                <li>
                  <Link to="/iletisim">İletişim</Link>
                </li>
                <li>
                  <Link to="/yardim">Sık Sorulan Sorular</Link>
                </li>
                <li>
                  <a
                    href="https://api.whatsapp.com/send?phone=905322266894"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Canlı Yardım
                  </a>
                </li>
                <li>
                  <Link to="/kvkk">Kişisel Verileri Koruma Kanunu</Link>
                </li>
                <li>
                  <Link to="/gizlilik-politikasi">Gizlilik Politikası</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
              </ul>
            </div>
            <div className="col-social-media">
              <strong>Sosyal Medya Hesaplarımız</strong>
              <div className="footer-social">
                {socials.map((social) => (
                  <a
                    key={social.link}
                    href={social.link}
                    title={social.text}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {social.icon}
                  </a>
                ))}
              </div>
              <strong>Ücretsiz Kataloğumuza Kayıt Olun</strong>
              <MarketingEmailForm target="footer" />
            </div>
            <div className="col-mobile-app">
              <strong>Mobil Uygulamalarımız</strong>
              <ul>
                <li>
                  <a
                    href="https://apps.apple.com/us/app/komb-in/id1577230539"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img src="/img/store/app-store.svg" alt="App Store" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=in.komb.kombin"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img src="/img/store/google-play.svg" alt="Google Play" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://appgallery.huawei.com/#/app/C104518593"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img src="/img/store/app-gallery.svg" alt="App Gallery" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright">
            © 2021 PixelPerfect Yazılım ve İnternet Hizmetleri LTD. ŞTİ.
          </div>
        </footer>
      ) : null}

      <footer className="footer-links">
        <div className="payment">
          <div>Güvenli Alışveriş</div>
          <img
            src="/img/iyzico/footer_iyzico_ile_ode/Colored/logo_band_colored.svg"
            alt="iyzico ile Öde"
            height={32}
          />
        </div>
      </footer>
    </>
  );
}
