import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { breadcrumbClear } from '../services/store/actions';
import ProductViewed from '../components/product/ProductViewed';
import CombineViewed from '../components/combine/CombineViewed';
import NotFoundComponent from '../components/layout/NotFound';

export default function NotFound() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(breadcrumbClear());
  }, []);

  return (
    <>
      <NotFoundComponent />
      <CombineViewed />
      <ProductViewed />
    </>
  );
}
