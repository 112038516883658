import React from 'react';
import { useWindowSize } from '../../services/util/WindowSize';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import './Header.scss';

export default function Header() {
  const device = useWindowSize();
  return (
    <>
      {device.isDesktop ? <HeaderDesktop /> : null}
      {device.isMobile ? <HeaderMobile /> : null}
    </>
  );
}
