import { gql } from '@apollo/client';
import { client } from './Api';
import { store } from './store/store';
import {
  favoriteProductCreate,
  favoriteProductDelete,
  favoriteCombineCreate,
  favoriteCombineDelete,
  notificationPush,
} from './store/actions';

export const favoriteProductDeleteSrv = async (productId: string) => {
  try {
    await client.mutate({
      mutation: gql`
        mutation favoriteProductDelete($productId: ID) {
          favoriteProductDelete(productId: $productId) {
            status
          }
        }
      `,
      variables: {
        productId,
      },
    });
    store.dispatch(favoriteProductDelete({ productId }));
    store.dispatch(
      notificationPush({
        type: 'success',
        text: 'Favorilerinizden çıkarıldı.',
      }),
    );
  } catch (e) {
    store.dispatch(
      notificationPush({
        type: 'danger',
        text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
      }),
    );
  }
};

export const favoriteProductCreateSrv = async (productId: string) => {
  try {
    await client.mutate({
      mutation: gql`
        mutation favoriteProductCreate($productId: ID) {
          favoriteProductCreate(productId: $productId) {
            status
          }
        }
      `,
      variables: {
        productId,
      },
    });
    store.dispatch(favoriteProductCreate({ productId }));
    store.dispatch(
      notificationPush({
        type: 'success',
        text: 'Favorilerinize eklendi.',
      }),
    );
  } catch (e) {
    store.dispatch(
      notificationPush({
        type: 'danger',
        text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
      }),
    );
  }
};

export const favoriteCombineDeleteSrv = async (combineId: string) => {
  try {
    await client.mutate({
      mutation: gql`
        mutation favoriteCombineDelete($combineId: ID) {
          favoriteCombineDelete(combineId: $combineId) {
            status
          }
        }
      `,
      variables: {
        combineId,
      },
    });
    store.dispatch(favoriteCombineDelete({ combineId }));
    store.dispatch(
      notificationPush({
        type: 'success',
        text: 'Favorilerinizden çıkarıldı.',
      }),
    );
  } catch (e) {
    store.dispatch(
      notificationPush({
        type: 'danger',
        text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
      }),
    );
  }
};

export const favoriteCombineCreateSrv = async (combineId: string) => {
  try {
    await client.mutate({
      mutation: gql`
        mutation favoriteCombineCreate($combineId: ID) {
          favoriteCombineCreate(combineId: $combineId) {
            status
          }
        }
      `,
      variables: {
        combineId,
      },
    });
    store.dispatch(favoriteCombineCreate({ combineId }));
    store.dispatch(
      notificationPush({
        type: 'success',
        text: 'Favorilerinize eklendi.',
      }),
    );
  } catch (e) {
    store.dispatch(
      notificationPush({
        type: 'danger',
        text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
      }),
    );
  }
};
