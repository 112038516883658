import React from 'react';

interface HighlightProps {
  stack: string;
  needle: string;
}

export const Highlight = ({ stack, needle }: HighlightProps) => {
  if (!stack) {
    return <>{stack}</>;
  }

  const parts = stack.split(new RegExp(`(${needle})`, 'gi'));

  return (
    <>
      {parts.map((part, i) => (
        <span
          key={String(i)}
          style={part.toLowerCase() === needle.toLowerCase() ? { fontWeight: 'bold' } : {}}
        >
          {part}
        </span>
      ))}
    </>
  );
};
