import React from 'react';
import { useWindowSize } from '../../services/util/WindowSize';
import './Main.scss';

export default function Main({ children }: ContentProps) {
  const device = useWindowSize();
  const style: any = {};

  if (device.isMobile) {
    style.height = device.height - 124;
  }

  return <main style={style}>{children}</main>;
}

type ContentProps = {
  children: any;
};
