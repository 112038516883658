import { Action } from '../../Types';
import { MENU_CLOSE, MENU_OPEN, MENU_TOGGLE } from '../actionTypes';

type Layout = {
  menuOpen: boolean;
};

const initialState: Layout = {
  menuOpen: false,
};

export default function reducer(state = initialState, { type }: Action) {
  switch (type) {
    case MENU_OPEN:
      return {
        ...state,
        menuOpen: true,
      };
    case MENU_CLOSE:
      return {
        ...state,
        menuOpen: false,
      };
    case MENU_TOGGLE:
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    default:
      return state;
  }
}
