import React from 'react';

interface DetailMoreProps {
  detail: string;
}

export function DetailMore({ detail }: DetailMoreProps) {
  let detailMoreContent = detail ? detail.trim() : '';
  detailMoreContent = detailMoreContent.replace(/^\s+|\s+$/g, '');
  detailMoreContent = detailMoreContent.replace(/^\n+|\n+$/g, '');

  if (!detailMoreContent) {
    return <></>;
  }

  return (
    <>
      <div className="card-text text-pre-line">{detailMoreContent}</div>
    </>
  );
}
