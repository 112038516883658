import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { FaShoppingBag } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';
import Currency from '../format/Currency';
import Percent from '../format/Percent';
import Counter from '../input/Counter';
import ProductModal from './ProductModal';
import { Product as IProduct, ProductVariant } from '../../services/Types';
import { cartProductCreate, notificationPush, signInModalShow } from '../../services/store/actions';
import * as RouteGenerator from '../../services/route/RouteGenerator';
import {
  selectAuthenticated,
  selectColors,
  selectFavoriteProduct,
} from '../../services/store/selectors';
import { favoriteProductCreateSrv, favoriteProductDeleteSrv } from '../../services/Favorite';
import { imageUrl } from '../../services/Image';
import getElementOffset from '../../services/util/ElementOffset';
import { useWindowSize } from '../../services/util/WindowSize';
import Title from '../format/Title';
import './Product.scss';

interface ProductProps {
  product: IProduct;
  layout?: 'horizontal' | 'vertical';
  defaultColorId?: any;
  defaultSizeId?: any;
  onSelectColorId?: any;
  onSelectSizeId?: any;
  showPurchaseOptions?: boolean;
}

export default function Product({
  product,
  layout,
  defaultColorId,
  defaultSizeId,
  onSelectColorId,
  onSelectSizeId,
  showPurchaseOptions,
}: ProductProps) {
  const { productId, title, images, variants, brand } = product;

  const dispatch = useDispatch();
  const device = useWindowSize();
  const authenticated = useSelector(selectAuthenticated);
  const favorites = useSelector(selectFavoriteProduct);
  const favorite = favorites && favorites.includes(productId);

  const [imageIndex, setImageIndex] = useState(0);
  const [quickRender, setQuickRender] = useState(false);
  const [quickShow, setQuickShow] = useState(false);

  const colors = useSelector(selectColors);
  const [colorIds, setColorIds] = useState<string[]>([]);
  const [selectedColorId, setSelectedColorId] = useState<string>(defaultColorId);
  const [selectedSizeId, setSelectedSizeId] = useState<string>(defaultSizeId);
  const [selectedAmount, setSelectedAmount] = useState(1);
  const [isTouched, setIsTouched] = useState(false);

  const productImageRef = useRef(null);

  useEffect(() => {
    const colorIds = images.reduce((colorList: string[], image) => {
      if (!colorList.includes(image.colorId)) {
        colorList.push(image.colorId);
      }
      return colorList;
    }, []);
    setColorIds(colorIds);
    setSelectedColorId(colorIds[0]);
  }, []);

  // Set selected color
  useEffect(() => {
    if (selectedColorId && onSelectColorId) {
      onSelectColorId(selectedColorId);
    }
  }, [selectedColorId]);

  // Set selected size
  useEffect(() => {
    if (selectedSizeId && onSelectSizeId) {
      onSelectSizeId(selectedSizeId);
    }
  }, [selectedSizeId]);

  // Set selected color
  useEffect(() => {
    if (defaultColorId) {
      setSelectedColorId(defaultColorId);
    }
  }, [defaultColorId]);

  // Set selected size
  useEffect(() => {
    if (defaultSizeId) {
      setSelectedSizeId(defaultSizeId);
    }
  }, [defaultSizeId]);

  const handleMouseLeave = () => {
    setImageIndex(0);
  };

  const handleMouseMove = (event: any) => {
    if (productImageRef.current && event) {
      const offset = getElementOffset(productImageRef.current);

      const imageIndex = Math.floor(
        // @ts-ignore
        ((event.pageX - offset.left) / offset.width) * imagesColor.length,
      );

      setImageIndex(imageIndex);
    }
  };

  const handleFavorite = async () => {
    if (!authenticated) {
      dispatch(signInModalShow());
    } else if (favorite) {
      favoriteProductDeleteSrv(productId);
    } else {
      favoriteProductCreateSrv(productId);
    }
  };

  const handleAddToBasket = () => {
    if (selectedSizeId && selectedColorId) {
      dispatch(
        cartProductCreate({
          productId,
          amount: selectedAmount,
          sizeId: selectedSizeId,
          colorId: selectedColorId,
          priceOriginal: variant.priceOriginal,
          priceFinal: variant.priceFinal,
          discount: 0,
        }),
      );

      dispatch(
        notificationPush({
          type: 'success',
          text: 'Ürün sepetinize eklendi.',
        }),
      );
    } else {
      setIsTouched(true);
    }
  };

  const handleShowQuick = () => {
    setQuickShow(true);
    setQuickRender(true);
  };

  let variantsFiltered = [...variants];
  if (selectedColorId) {
    variantsFiltered = variantsFiltered.filter((variant) => variant.colorId === selectedColorId);
  }
  if (selectedSizeId) {
    variantsFiltered = variantsFiltered.filter((variant) => variant.sizeId === selectedSizeId);
  }

  let variant: ProductVariant;
  if (variantsFiltered.length >= 1) {
    variant = variantsFiltered[0];
  } else {
    variant = variants[0];
  }

  const imagesColor = images.filter((image) => image.colorId === selectedColorId);
  return (
    <>
      <div
        className={`product ${layout ? `product-${layout}` : 'product-vertical'}`}
        role="presentation"
      >
        {device.isDesktop ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`favorite-product-${productId}`}>
                {favorite ? 'Favorilerden Çıkar' : 'Favorilere Ekle'}
              </Tooltip>
            }
          >
            <div
              className={`product-favorite ${favorite ? 'product-favorite-active' : ''}`}
              role="presentation"
              onClick={handleFavorite}
            >
              {favorite ? <AiFillHeart /> : <AiOutlineHeart />}
            </div>
          </OverlayTrigger>
        ) : (
          <div
            className={`product-favorite ${favorite ? 'product-favorite-active' : ''}`}
            role="presentation"
            onClick={handleFavorite}
          >
            {favorite ? <AiFillHeart /> : <AiOutlineHeart />}
          </div>
        )}

        <div className="product-image-col">
          <div
            className="product-image-wrapper"
            ref={productImageRef}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
            <Link
              to={RouteGenerator.productDetail(
                productId,
                product.slug,
                variant.color.colorId,
                variant.color.slug,
                brand.brandId,
                brand.slug,
              )}
              className="product-image"
            >
              {imagesColor.map((image, index) => {
                const img = (
                  <img
                    className={index === imageIndex ? 'active' : ''}
                    src={imageUrl(image.image.path, 'list')}
                    alt=""
                  />
                );

                return (
                  <React.Fragment key={image.image.imageId}>
                    {img}
                  </React.Fragment>
                );
              })}
            </Link>

            {imagesColor.length > 1 ? (
              <div className="product-image-variants">
                {imagesColor.map((image, index) => (
                  <div key={image.image.imageId} className={index === imageIndex ? 'active' : ''} />
                ))}
              </div>
            ) : null}

            <div className="product-quick">
              <button
                type="button"
                className="btn btn-sm btn-kombin"
                onClick={() => handleShowQuick()}
              >
                Hızlı Görüntüle
              </button>
            </div>
          </div>
        </div>

        <div className="product-detail">
          <div className="product-brand">{brand.title}</div>
          <div className="product-title">
            <Title text={title} />
          </div>

          {/*
          {rate >= 1 ? (
            <div className="product-rate">
              <Rate rate={rate} />
            </div>
          ) : null}
          */}

          <div className="product-price">
            {variant.priceOriginal !== variant.priceFinal && variant.priceOriginal !== 0 ? (
              <>
                <span className="product-price-initial">
                  <Currency price={variant.priceOriginal} />
                </span>
                <span> </span>
              </>
            ) : null}
            <span className="product-price-final">
              <Currency price={variant.priceFinal} />
            </span>
            <span style={{ flex: 1 }}> </span>
            {variant.priceOriginal !== variant.priceFinal && variant.priceOriginal !== 0 ? (
              <span className="product-discount">
                <span>-</span>
                <Percent original={variant.priceOriginal} final={variant.priceFinal} />
              </span>
            ) : null}
          </div>

          {colorIds.length > 1 ? (
            <>
              {layout === 'horizontal' ? (
                <div className="product-option-title">
                  <strong>Renk: </strong>
                </div>
              ) : null}
              <div className="product-variant">
                <ul>
                  {colorIds.map((colorId, index) => {
                    if (index < 4) {
                      const image = images.find((image) => image.colorId === colorId);
                      const color = colors.find((color) => color.colorId === colorId);
                      if (image && color) {
                        return (
                          <li
                            key={colorId}
                            role="presentation"
                            className={selectedColorId === colorId ? 'product-variant-active' : ''}
                            onClick={() => setSelectedColorId(colorId)}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`product-color-${productId}-${colorId}`}>
                                  {color.title}
                                </Tooltip>
                              }
                            >
                              <div>
                                <img src={imageUrl(image.image.path, 'option')} alt="" />
                              </div>
                            </OverlayTrigger>
                          </li>
                        );
                      }
                    }
                    return null;
                  })}
                  {colorIds.length > 4 ? (
                    <li
                      role="presentation"
                      className="product-variant-more"
                      onClick={() => handleShowQuick()}
                    >
                      <div>+{colorIds.length - 4}</div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </>
          ) : null}

          {layout === 'horizontal' ? (
            <>
              <div className="product-option-title">
                <strong>Beden: </strong>
              </div>

              {variants.length > 0 ? (
                <div className="product-size">
                  <ul>
                    {variants
                      .filter((variant) => variant.color.colorId === selectedColorId)
                      .map((variant) => {
                        return (
                          <li
                            key={variant.size.sizeId}
                            role="presentation"
                            className={
                              selectedSizeId === variant.size.sizeId ? 'product-size-active' : ''
                            }
                          >
                            <button
                              type="button"
                              className={`btn ${
                                variant.size.sizeId === selectedSizeId
                                  ? 'btn-size'
                                  : 'btn-size-secondary'
                              }`}
                              onClick={() => setSelectedSizeId(variant.size.sizeId)}
                              disabled={variant.stock === 0}
                            >
                              {variant.size.title}
                            </button>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ) : null}

              {isTouched && !selectedSizeId ? (
                <div className="product-warning">Lütfen beden seçin.</div>
              ) : null}

              {showPurchaseOptions !== false ? (
                <>
                  <div className="product-option-title">
                    <strong>Adet: </strong>
                  </div>

                  <div className="product-add-to-cart">
                    <Counter
                      min={1}
                      max={variant?.stock}
                      value={selectedAmount}
                      onChange={(val) => {
                        let value = val;

                        if (value > variant.stock) {
                          value = variant?.stock;
                        }

                        if (value < 1) {
                          value = 1;
                        }

                        setSelectedAmount(value);
                      }}
                    />
                    <button
                      className="btn btn-kombin mr-2"
                      type="button"
                      onClick={() => handleAddToBasket()}
                    >
                      <FaShoppingBag /> Sepetime Ekle
                    </button>
                  </div>

                  {variant.stock < 5 ? (
                    <div className="product-out-of-stock-warning">
                      Acele edin. Son {variant.stock} ürün.
                    </div>
                  ) : null}

                  {isTouched && !selectedAmount ? (
                    <div className="product-warning">Lütfen adet girin.</div>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </div>

      {quickRender ? (
        <ProductModal
          product={product}
          colorId={selectedColorId}
          show={quickShow}
          onDialogCancel={() => setQuickShow(false)}
        />
      ) : null}
    </>
  );
}
