import React from 'react';
import PropTypes from 'prop-types';
import './Error.scss';

export default function Error({ message }: { message?: string }) {
  return (
    <div className="error">
      <span>{message || 'Bir hata oluştu lütfen tekrar deneyin...'}</span>
    </div>
  );
}

Error.propTypes = {
  message: PropTypes.string,
};

Error.defaultProps = {
  message: '',
};
