import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectCookieWarning } from '../../services/store/selectors';
import { cookieWarningAccept } from '../../services/store/actions';
import { useWindowSize } from '../../services/util/WindowSize';
import './CookieWarning.scss';

export default function CookieWarning() {
  const dispatch = useDispatch();
  const device = useWindowSize();
  const cookieWarning = useSelector(selectCookieWarning);

  const handleClose = () => {
    dispatch(cookieWarningAccept());
  };

  if (cookieWarning.accepted) {
    return null;
  }

  if (device.isMobile) {
    return null;
  }

  return (
    <>
      <div className="cookie-warning">
        <div className="container">
          <p>
            Komb.in, size en iyi hizmeti sunabilmek için çerezleri kullanmaktadır. Hizmetlerimizi
            kullanmaya devam etmeniz, çerez politikamızı kabul ettiğiniz anlamına gelecektir.
            Çerezler hakkında daha fazla bilgi için:
            <span> </span>
            <Link to="/gizlilik-politikasi">Gizlilik Politikası</Link>
            <span>, </span>
            <Link to="/cerez-politikasi">Çerez Politikamız</Link>
            <span> </span>
            ve
            <span> </span>
            <Link to="/kvkk">Kişisel Verileri Koruma Kanunu</Link>
          </p>
          <div>
            <button type="button" onClick={() => handleClose()} className="btn btn-kombin">
              Kabul Et ve Kapat
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
