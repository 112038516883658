import React, { useState } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { Combine as ICombine } from '../../services/Types';
import Combine from './Combine';
import './CombineListHorizontal.scss';
import { useWindowSize } from '../../services/util/WindowSize';

interface CombineListHorizontalProps {
  combines: ICombine[];
}

export default function CombineListHorizontal({ combines }: CombineListHorizontalProps) {
  const device = useWindowSize();
  const combinesCountVisible = device.isMobile ? 2 : 5;

  const [navigateIndex, setNavigationIndex] = useState(0);

  const navigate = (index: number) => {
    const element = document.querySelector('.combine-list-horizontal');

    if (element) {
      element.scroll(element.clientWidth / combinesCountVisible * index, 0);
    }
  };

  const scroll = () => {
    const element = document.querySelector('.combine-list-horizontal');

    if (element) {
      const index = Math.floor((element.scrollLeft / element.clientWidth) * 6);
      setNavigationIndex(index);
    }
  };

  const navigatePrev = () => {
    navigate(navigateIndex - combinesCountVisible);
  };

  const navigateNext = () => {
    navigate(navigateIndex + combinesCountVisible);
  };

  return (
    <>
      <div className="combine-list-wrapper">
        <div className="combine-list-horizontal" onScrollCapture={scroll}>
          {combines.map((combine) => (
            <Combine key={combine.combineId} combine={combine} />
          ))}
        </div>

        {device.isDesktop && combines.length > 5 ? (
          <>
            {navigateIndex > 0 ? (
              <div
                className="combine-list-horizontal-prev"
                onClick={navigatePrev}
                role="presentation"
              >
                <AiOutlineArrowLeft />
              </div>
            ) : null}

            {navigateIndex < combines.length - combinesCountVisible ? (
              <div
                className="combine-list-horizontal-next"
                onClick={navigateNext}
                role="presentation"
              >
                <AiOutlineArrowRight />
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}
