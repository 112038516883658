import { Action, Rate } from '../../Types';

const initialState: Rate[] = [
  {
    rate: 0,
    title: 'Puansız',
  },
  {
    rate: 1,
    title: '1 ve 1,9 arası puanlı',
  },
  {
    rate: 2,
    title: '2 ve 2,9 arası puanlı',
  },
  {
    rate: 3,
    title: '3 ve 3,9 arası puanlı',
  },
  {
    rate: 4,
    title: '4 ve 4 üstü puanlı',
  },
];

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    default:
      return state;
  }
}
