let uriApi = 'https://api.komb.in';
let uriWs = 'wss://api.komb.in/subscriptions';
let uriWeb = 'https://komb.in';
let uriPartner = 'https://partner.komb.in';
let uriImageService = 'https://image.komb.in';

if (process.env.NODE_ENV === 'development') {
  uriApi = 'http://localhost:3001';
  uriWs = 'ws://localhost:3001/subscriptions';
  uriWeb = 'http://localhost:3000';
  uriPartner = 'http://localhost:4000';
  uriImageService = 'https://image.dev.komb.in';
}

const recaptchaSiteKey = '6LfEk_4aAAAAALziG5mMxp1EQH3t9pAm2wNakysC';

export default { uriApi, uriWs, uriWeb, uriPartner, uriImageService, recaptchaSiteKey };
