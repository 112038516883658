import React, { useState } from 'react';
import { imageUrl } from '../../services/Image';
import { useWindowSize } from '../../services/util/WindowSize';
import { CombineImage, ProductImage } from '../../services/Types';
import './ImageList.scss';

interface ImageListProps {
  images: ProductImage[] | CombineImage[];
}

export default function ImageList({ images }: ImageListProps) {
  const device = useWindowSize();
  const [navigateIndex, setNavigationIndex] = useState(0);

  const navigate = (index: number) => {
    const element = document.querySelector('.item-image');

    if (element) {
      element.scroll(element.clientWidth * index, 0);
    }
  };

  const scroll = () => {
    const element = document.querySelector('.item-image');

    if (element) {
      const index = Math.floor(element.scrollLeft / element.clientWidth);
      setNavigationIndex(index);
    }
  };

  const navigatePrev = () => {
    if (navigateIndex > 0) {
      navigate(navigateIndex - 1);
    } else {
      navigate(images.length - 1);
    }
  };

  const navigateNext = () => {
    if (navigateIndex < images.length - 1) {
      navigate(navigateIndex + 1);
    } else {
      navigate(0);
    }
  };

  return (
    <div className="item-image-voltran">
      {device.isDesktop ? (
        <div className="item-image-thumbnail">
          {images.map((image, index) => (
            <div key={image.image.imageId} className={navigateIndex === index ? 'active' : ''}>
              <div>
                <img
                  src={imageUrl(image.image.path, 'option')}
                  alt=""
                  role="presentation"
                  onClick={() => navigate(index)}
                />
              </div>
            </div>
          ))}
        </div>
      ) : null}

      <div className="item-image-wrapper">
        <div className="item-image-slider">
          <div className="item-image" onScrollCapture={scroll}>
            {images.map((image) => (
              <div
                key={image.image.imageId}
                className="item-image-holder"
                id={`item-image-holder-${image.image.imageId}`}
              >
                <img
                  src={imageUrl(image.image.path, 'list')}
                  alt=""
                  role="presentation"
                  // onClick={() => setShowImageModal(true)}
                />
              </div>
            ))}
          </div>
        </div>

        {images.length > 1 ? (
          <div className="item-image-navigation">
            {device.isDesktop ? (
              <div
                className="item-image-navigation-prev"
                role="presentation"
                onClick={() => navigatePrev()}
              >
                <span className="material-icons-outlined">keyboard_arrow_left</span>
              </div>
            ) : null}
            {device.isMobile ? (
              <div className="item-image-navigation-list">
                {images.map((image, index) => (
                  <div
                    key={image.image.imageId}
                    onClick={() => navigate(index)}
                    role="presentation"
                    className={navigateIndex === index ? 'active' : ''}
                  >
                    {image.image.imageId}
                  </div>
                ))}
              </div>
            ) : null}
            {device.isDesktop ? (
              <div
                className="item-image-navigation-next"
                role="presentation"
                onClick={() => navigateNext()}
              >
                <span className="material-icons-outlined">keyboard_arrow_right</span>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
