import { Action } from '../../Types';
import { COOKIE_WARNING_ACCEPT } from '../actionTypes';

type CookieWarning = {
  accepted: boolean;
};

const initialState: CookieWarning = {
  accepted: !!localStorage.getItem('acceptedCookieWarning'),
};

export default function reducer(state = initialState, { type }: Action) {
  switch (type) {
    case COOKIE_WARNING_ACCEPT:
      localStorage.setItem('acceptedCookieWarning', '1');

      return {
        ...state,
        accepted: true,
      };
    default:
      return state;
  }
}
