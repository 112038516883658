import React from 'react';
import { useWindowSize } from '../../services/util/WindowSize';
import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';
import './Footer.scss';

export default function Footer() {
  const device = useWindowSize();
  return (
    <>
      {device.isDesktop ? <FooterDesktop /> : null}
      {device.isMobile ? <FooterMobile /> : null}
    </>
  );
}
