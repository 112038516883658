import React from 'react';
import PropTypes from 'prop-types';
import './Loading.scss';

export default function Loading({ title }: { title?: string }) {
  return (
    <div className="loading">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <span>{title || 'Yükleniyor...'}</span>
    </div>
  );
}

Loading.propTypes = {
  title: PropTypes.string,
};

Loading.defaultProps = {
  title: '',
};
