import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCartCount, selectUser } from '../../services/store/selectors';
import './HeaderMobile.scss';

export default function HeaderMobile() {
  const location = useLocation();
  const user = useSelector(selectUser);
  const itemsCount = useSelector(selectCartCount);

  let content;
  if (location.pathname === '/hesabim') {
    content = (
      <>
        <div className="header-title">Hesabım</div>
        {user ? (
          <>
            {user.nameFirst && user.nameLast ? (
              <div className="header-more">
                {user.nameFirst} {user.nameLast}
              </div>
            ) : (
              <div className="header-more">{user.email}</div>
            )}
          </>
        ) : null}
      </>
    );
  } else if (location.pathname.startsWith('/giris-yap')) {
    content = (
      <>
        <div className="header-title">Üye Girişi</div>
      </>
    );
  } else if (location.pathname.startsWith('/kayit-ol')) {
    content = (
      <>
        <div className="header-title">
          <Link to="/giris-yap">
            <MdArrowBack />
          </Link>
          <span> </span>
          Kayıt Ol
        </div>
      </>
    );
  } else if (location.pathname.startsWith('/sifremi-unuttum')) {
    content = (
      <>
        <div className="header-title">
          <Link to="/giris-yap">
            <MdArrowBack />
          </Link>
          <span> </span>
          Şifremi Unuttum
        </div>
      </>
    );
  } else if (location.pathname.startsWith('/sifremi-unuttum-dogrulama')) {
    content = (
      <>
        <div className="header-title">
          <Link to="/giris-yap">
            <MdArrowBack />
          </Link>
          <span> </span>
          Şifremi Unuttum - Doğrula
        </div>
      </>
    );
  } else if (location.pathname.startsWith('/kombin')) {
    content = (
      <>
        <div className="header-title">Kombinler</div>
      </>
    );
  } else if (
    location.pathname.startsWith('/urunler') ||
    location.pathname.startsWith('/erkek/') ||
    location.pathname.startsWith('/kadin/') ||
    location.pathname.startsWith('/cocuk/erkek') ||
    location.pathname.startsWith('/cocuk/kiz') ||
    location.pathname.startsWith('/bebek/erkek') ||
    location.pathname.startsWith('/bebek/kiz')
  ) {
    content = (
      <>
        <div className="header-title">Ürünler</div>
      </>
    );
  } else if (location.pathname === '/hesabim/favorilerim') {
    content = (
      <>
        <div className="header-title">Favorilerim</div>
      </>
    );
  } else if (location.pathname.startsWith('/hesabim/siparislerim')) {
    content = (
      <>
        <div className="header-title">
          <Link to="/hesabim">
            <MdArrowBack />
          </Link>
          <span> </span>
          Siparişlerim
        </div>
      </>
    );
  } else if (location.pathname.startsWith('/hesabim/degerlendirmelerim')) {
    content = (
      <>
        <div className="header-title">
          <Link to="/hesabim">
            <MdArrowBack />
          </Link>
          <span> </span>
          Değerlendirmelerim
        </div>
      </>
    );
  } else if (location.pathname.startsWith('/hesabim/hesap-bilgilerim')) {
    content = (
      <>
        <div className="header-title">
          <Link to="/hesabim">
            <MdArrowBack />
          </Link>
          <span> </span>
          Hesap Bilgilerim
        </div>
      </>
    );
  } else if (location.pathname.startsWith('/hesabim/adreslerim')) {
    content = (
      <>
        <div className="header-title">
          <Link to="/hesabim">
            <MdArrowBack />
          </Link>
          <span> </span>
          Adreslerim
        </div>
      </>
    );
  } else if (location.pathname.startsWith('/hesabim/bildirimlerim')) {
    content = (
      <>
        <div className="header-title">
          <Link to="/hesabim">
            <MdArrowBack />
          </Link>
          <span> </span>
          Bildirimlerim
        </div>
      </>
    );
  } else if (location.pathname.startsWith('/hesabim/indirim-kuponlarim')) {
    content = (
      <>
        <div className="header-title">
          <Link to="/hesabim">
            <MdArrowBack />
          </Link>
          <span> </span>
          İndirim Kuponlarım
        </div>
      </>
    );
  } else if (location.pathname.startsWith('/yardim')) {
    content = (
      <>
        <div className="header-title">Yardım</div>
      </>
    );
  } else if (location.pathname.startsWith('/blog')) {
    content = (
      <>
        <div className="header-title">Blog</div>
      </>
    );
  } else if (location.pathname === '/sepetim') {
    content = (
      <>
        <div className="header-title">
          {itemsCount > 0 ? `Sepetim | ${itemsCount} Ürün` : `Sepetim`}
        </div>
      </>
    );
  } else if (location.pathname === '/siparis-olustur') {
    content = (
      <>
        <div className="header-title">Sipariş Oluştur</div>
      </>
    );
  } else if (location.pathname.startsWith('/siparis-odeme/')) {
    content = (
      <>
        <div className="header-title">Ödeme</div>
      </>
    );
  } else {
    content = (
      <div className="header-menu-title">
        <Link to="/">
          <img src="/img/logo/logo.svg" alt="komb.in" height={14} />
        </Link>
      </div>
    );
  }

  return <header>{content}</header>;
}
