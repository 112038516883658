import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaInternetExplorer } from 'react-icons/fa';
import { isIE } from 'react-device-detect';

import Search from '../input/Search';
import { signOut } from '../../services/store/actions';
import { useWindowSize } from '../../services/util/WindowSize';
import HeaderSearchDesktop from './HeaderSearchDesktop';
import { selectAuthenticated, selectCartCount } from '../../services/store/selectors';
import './HeaderDesktop.scss';

export default function HeaderDesktop() {
  const router = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const authenticated = useSelector(selectAuthenticated);
  const headerCartCount = useSelector(selectCartCount);
  const [keyword, setKeyword] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const device = useWindowSize();

  return (
    <header>
      {isIE ? (
        <div className="header-banner-ie">
          <FaInternetExplorer />
          Internet Explorer desteklenmiyor. Lütfen güncel bir web tarayıcısı kullanın.
        </div>
      ) : null}

      {device.isDesktop ? (
        <div className="header-top">
          <div className="header-top-left">
            <Link to="/satis-ortakligi">Satış Ortaklığı</Link>
            {/*
            <Link to="/ucretsiz-e-ticaret-sitesi">Ücretiz E-ticaret Sitesi</Link>
            */}
          </div>
          <div className="header-top-right">
            <Link to="/kolay-iade">Kolay İade</Link>
            <Link to="/hesabim/siparislerim">Sipariş Takibi</Link>
            <a
              href="https://api.whatsapp.com/send?phone=905322266894"
              target="_blank"
              rel="noreferrer"
            >
              Canlı Yardım
            </a>
          </div>
        </div>
      ) : null}

      <div className="header-menu">
        <div className="header-menu-title">
          <Link to="/">
            <img src="/img/logo/logo.svg" alt="komb.in" height={20} />
          </Link>
        </div>

        {device.isDesktop ? (
          <div className="header-menu-search">
            <form autoComplete="chrome-off">
            <Search
              placeholder="Stil, marka, kategori, ürün ara..."
              value={keyword}
              onChange={(keyword: string) => setKeyword(keyword)}
              onEnter={(keyword: string) => {
                router.push(`/ara?keyword=${keyword}`);
                setKeyword('');
              }}
              onFocus={() => setSearchActive(true)}
              onBlur={() => {
                if (keyword === '') {
                  setTimeout(() => {
                    setSearchActive(false);
                  }, 1);
                }
              }}
              showSearchButton
              showClearButton={false}
            />
            </form>
            {searchActive ? (
              <HeaderSearchDesktop
                keyword={keyword}
                setKeyword={setKeyword}
                setSearchActive={setSearchActive}
              />
            ) : null}
          </div>
        ) : null}
        <div className="header-menu-button">
          {authenticated ? (
            <>
              <Link
                to="/hesabim/favorilerim"
                className={`${location.pathname === '/hesabim/favorilerim' ? 'active' : ''}`}
              >
                <span className="material-icons-outlined">favorite_border</span>
                <span className="text">Favorilerim</span>
              </Link>

              <div className="dropdown">
                <Link
                  to="/hesabim"
                  className={`
                    ${device.isDesktop ? 'dropdown-toggle' : ''} 
                    ${
                      location.pathname.includes('/hesabim') &&
                      location.pathname !== '/hesabim/favorilerim'
                        ? 'active'
                        : ''
                    }
                  `}
                >
                  <span className="material-icons-outlined">person</span>
                  <span className="text">Hesabım</span>
                </Link>

                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/hesabim/siparislerim">
                      Siparişlerim
                    </Link>
                  </li>
                  {/*
                  <li>
                    <Link className="dropdown-item" to="/hesabim/degerlendirmelerim">
                      Değerlendirmelerim
                    </Link>
                  </li>
                  */}
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/hesabim/hesap-bilgilerim/kullanici-bilgilerim"
                    >
                      Hesap Bilgilerim
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/hesabim/adreslerim">
                      Adreslerim
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/hesabim/indirim-kuponlarim">
                      İndirim Kuponlarım
                    </Link>
                  </li>
                  {/*
                  <li>
                    <Link className="dropdown-item" to="/hesabim/tercihlerim">
                      Tercihlerim
                    </Link>
                  </li>
                  */}
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={() => {
                        dispatch(signOut());
                        router.push('/');
                      }}
                    >
                      Güvenli Çıkış
                    </button>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <Link to="/giris-yap" className={location.pathname === '/giris-yap' ? 'active' : ''}>
              <span className="material-icons-outlined">login</span>
              <span className="text">Giriş&nbsp;Yap</span>
            </Link>
          )}
          <Link to="/sepetim" className={location.pathname === '/sepetim' ? 'active' : ''}>
            <span className="material-icons-outlined">shopping_cart</span>
            <span className="text">Sepetim</span>
            {headerCartCount > 0 ? <sup>{headerCartCount}</sup> : null}
          </Link>
        </div>
      </div>
    </header>
  );
}
