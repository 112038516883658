import { Action, Style } from '../../Types';
import { LOAD_INITIAL } from '../actionTypes';

const initialState: Style[] = [];

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case LOAD_INITIAL:
      return [...action.payload.styles];
    default:
      return state;
  }
}
