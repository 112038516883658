import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { RetryLink } from '@apollo/client/link/retry';
import config from './Config';

const httpLink = createHttpLink({
  uri: config.uriApi,
});

const wsLink = new WebSocketLink({
  uri: config.uriWs,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: localStorage.getItem('token') || null,
    },
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  const headersUpdated = {
    ...headers,
  };

  if (token) {
    headersUpdated.Authorization = token;
  }

  return {
    headers: headersUpdated,
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  retryLink.concat(authLink.concat(httpLink)),
);

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});
