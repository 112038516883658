import { combineReducers } from 'redux';

import auth from './Auth';
import user from './User';
import initial from './Initial';
import cart from './Cart';
import breadcrumb from './Breadcrumb';
import notification from './Notification';
import gender from './Gender';
import department from './Department';
import departmentStyle from './DepartmentStyle';
import departmentCategory from './DepartmentCategory';
import brand from './Brand';
import category from './Category';
import merchant from './Merchant';
import color from './Color';
import size from './Size';
import rate from './Rate';
import style from './Style';
import vitrine from './Vitrine';
import layout from './Layout';
import cookieWarning from './CookieWarning';
import latestVisit from './LatestVisit';

export default combineReducers({
  auth,
  user,
  initial,
  cart,
  breadcrumb,
  notification,
  gender,
  department,
  departmentStyle,
  departmentCategory,
  brand,
  category,
  merchant,
  color,
  size,
  rate,
  style,
  vitrine,
  layout,
  cookieWarning,
  latestVisit,
});
