import { Action, User as IUser } from '../../Types';
import {
  LOAD_INITIAL_USER,
  FAVORITE_ADD_COMBINE,
  FAVORITE_ADD_PRODUCT,
  FAVORITE_DELETE_COMBINE,
  FAVORITE_DELETE_PRODUCT,
  SIGN_OUT,
} from '../actionTypes';

type User = {
  user?: IUser;
  favoritesCombine: string[];
  favoritesProduct: string[];
};

const initialState: User = {
  user: undefined,
  favoritesCombine: [],
  favoritesProduct: [],
};

export default function reducer(state = initialState, { type, payload }: Action) {
  switch (type) {
    case LOAD_INITIAL_USER:
      return {
        ...state,
        user: payload.user,
        favoritesCombine: payload.favoritesCombine,
        favoritesProduct: payload.favoritesProduct,
      };
    case SIGN_OUT:
      return { ...initialState };
    case FAVORITE_ADD_COMBINE:
      return {
        ...state,
        favoritesCombine: [payload.combineId, ...state.favoritesCombine],
      };
    case FAVORITE_ADD_PRODUCT:
      return {
        ...state,
        favoritesProduct: [payload.productId, ...state.favoritesProduct],
      };
    case FAVORITE_DELETE_COMBINE:
      return {
        ...state,
        favoritesCombine: state.favoritesCombine.filter(
          (combineId) => combineId !== payload.combineId,
        ),
      };
    case FAVORITE_DELETE_PRODUCT:
      return {
        ...state,
        favoritesProduct: state.favoritesProduct.filter(
          (productId) => productId !== payload.productId,
        ),
      };
    default:
      return state;
  }
}
