import { Action, DepartmentStyle } from '../../Types';
import { LOAD_INITIAL } from '../actionTypes';

const initialState: DepartmentStyle[] = [];

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case LOAD_INITIAL:
      return [...action.payload.departmentStyle];
    default:
      return state;
  }
}
