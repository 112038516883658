import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Routes, RouteStatus, RouteWithSubRoutes } from './services/route/Routes';
import { useWindowSize } from './services/util/WindowSize';
import { initial, initialUser } from './services/Initial';
import NotFound from './containers/NotFound';
import Meta from './components/layout/Meta';
import Header from './components/layout/Header';
import Main from './components/layout/Main';
import Footer from './components/layout/Footer';
import Navbar from './components/layout/Navbar';
import Breadcrumb from './components/layout/Breadcrumb';
import Notifications from './components/layout/Notifications';
import ScrollToTop from './components/layout/ScrollToTop';
import SignInDialog from './components/SignInDialog';
import CookieWarning from './components/layout/CookieWarning';
import MarketingEmailModal from './components/marketing/MarketingEmailModal';
import { selectAuthenticated } from './services/store/selectors';
import './App.scss';

initial().then();

setTimeout(() => {
  const element = window.document.getElementById('kombin-splash');
  if (element) {
    element.className = 'remove';

    setTimeout(() => {
      element.remove();
    }, 1000);
  }
}, 3000);

export default function App() {
  const authenticated = useSelector(selectAuthenticated);
  const device = useWindowSize();

  useEffect(() => {
    if (authenticated === undefined) {
      initialUser().then();
    }
  }, [authenticated]);

  let styles;
  if (device.isDesktop) {
    styles = { minHeight: device.height };
  }

  return (
    <div className={device.isDesktop ? 'container' : ''} style={styles}>
      <BrowserRouter>
        <Meta />
        <ScrollToTop />
        <Header />
        <Navbar />
        <Breadcrumb />
        <Main>
          <Suspense fallback={null}>
            <Switch>
              {Routes.map((route, i) => (
                <RouteWithSubRoutes key={String(i)} {...route} />
              ))}
              <RouteStatus statusCode={404}>
                <NotFound />
              </RouteStatus>
            </Switch>
          </Suspense>
        </Main>
        <Footer />
        <Notifications />
        <MarketingEmailModal />
        <SignInDialog />
        <CookieWarning />
      </BrowserRouter>
    </div>
  );
}
