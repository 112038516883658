export const account = () => {
  return `/hesabim`;
};

export const category = (slugCategory: string) => {
  return `/kategori/${slugCategory}`;
};

export const department = (departmentId: string) => {
  let slugDepartment = 'kategori';

  switch (departmentId) {
    case '1':
      slugDepartment = 'kadin';
      break;
    case '2':
      slugDepartment = 'erkek';
      break;
    case '3':
      slugDepartment = 'cocuk-kiz';
      break;
    case '4':
      slugDepartment = 'cocuk-erkek';
      break;
    case '5':
      slugDepartment = 'bebek-kiz';
      break;
    case '6':
      slugDepartment = 'bebek-erkek';
      break;
    default:
  }

  return `/${slugDepartment}`;
};

export const departmentCategory = (departmentId: string, slugCategory: string) => {
  let slugDepartment = 'kategori';

  switch (departmentId) {
    case '1':
      slugDepartment = 'kadin';
      break;
    case '2':
      slugDepartment = 'erkek';
      break;
    case '3':
      slugDepartment = 'cocuk-kiz';
      break;
    case '4':
      slugDepartment = 'cocuk-erkek';
      break;
    case '5':
      slugDepartment = 'bebek-kiz';
      break;
    case '6':
      slugDepartment = 'bebek-erkek';
      break;
    default:
  }

  return `/${slugDepartment}/${slugCategory}`;
};

export const productDetail = (
  productId: string,
  productSlug: string,
  colorId: string,
  colorSlug: string,
  brandId: string,
  brandSlug: string,
) => {
  return `/${brandSlug}/${productSlug}-${colorSlug}-p-${productId}-${colorId}`;
};

export const combineDetail = (
  combineId: string,
  combineSlug: string,
  styleId: string,
  styleSlug: string,
  combineVariantId: string,
  combineVariantSlug: string,
) => {
  return `/${styleSlug}/${combineSlug}-${combineVariantSlug}-c-${combineId}-${combineVariantId}`;
};
