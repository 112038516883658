import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductListHorizontal from './ProductListHorizontal';
import { selectLatestVisitProductIds } from '../../services/store/selectors';
import { latestVisitProductClean } from '../../services/store/actions';
import { loadProducts } from '../../services/Product';
import BlockSkeleton from '../skeleton/Block';
import './ProductViewed.scss';

interface ProductViewedProps {}

export default function ProductViewed({}: ProductViewedProps) {
  const dispatch = useDispatch();
  const latestVisitProductIds = useSelector(selectLatestVisitProductIds);

  const handleClean = () => {
    dispatch(latestVisitProductClean());
  };

  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (latestVisitProductIds.length > 0) {
      loadProducts({
        filters: {
          productIds: ['0', ...latestVisitProductIds],
          isActive: true,
          isApproved: true,
          isArchived: false,
          isInStock: true,
          isActiveMerchant: true,
        },
        options: {
          limit: 11,
        },
      }).then((products) => {
        if (products) {
          setProducts(products);
        }
      });
    } else {
      setProducts([]);
    }
  }, [latestVisitProductIds]);

  if (!latestVisitProductIds || latestVisitProductIds.length === 0) {
    return <></>;
  }

  if (!products || products.length === 0) {
    return (
      <div className="product-viewed">
        <h4>EN SON İNCELEDİĞİNİZ ÜRÜNLER</h4>
        <BlockSkeleton height={347} />
      </div>
    );
  }

  return (
    <div className="product-viewed">
      <h4>EN SON İNCELEDİĞİNİZ ÜRÜNLER</h4>
      <button
        type="button"
        className="btn btn-sm btn-light btn-viewed-clean"
        onClick={() => handleClean()}
      >
        Temizle
      </button>
      <ProductListHorizontal products={products} />
    </div>
  );
}
