import config from './Config';

function height(width: number) {
  return (width * 1.5).toFixed(0);
}

export const imageUrl = (
  imagePath: string,
  target:
    | 'list'
    | 'option'
    | 'detail'
    | 'social'
    | 'cart'
    | 'order'
    | 'opengraph'
    | 'style'
    | 'header'
    | 'blog',
) => {
  if (imagePath.startsWith('http')) {
    return imagePath;
  }

  let imageParams = '';

  let width = 1200;
  let quality = 80;
  let type = '';

  switch (target) {
    case 'opengraph':
    case 'list':
    case 'social':
    case 'blog':
      // type = 'webp';
      width = 600;
      imageParams = `w${width}-q${quality}`;
      break;
    case 'style':
      imageParams = `w600-h900`;
      break;
    case 'order':
    case 'cart':
    case 'option':
      type = 'webp';
      width = 120;
      imageParams = `w${width}-h${height(width)}-q${quality}`;
      break;
    case 'detail':
      type = 'webp';
      width = 1200;
      quality = 90;
      imageParams = `w${width}-h${height(width)}-q${quality}`;
      break;
    case 'header':
      type = 'webp';
      width = 1200;
      quality = 90;
      imageParams = `w${width}-q${quality}`;
      break;
    default:
  }

  return `${config.uriImageService}/${imageParams}/${imagePath}${type === 'webp' ? '.webp' : ''}`;
};
