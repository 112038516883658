export const BREADCRUMB_PUSH = 'BREADCRUMB_PUSH';
export const BREADCRUMB_CLEAR = 'BREADCRUMB_CLEAR';
export const BREADCRUMB_SET = 'BREADCRUMB_SET';

export const LOAD_INITIAL = 'LOAD_INITIAL';
export const LOAD_INITIAL_USER = 'LOAD_INITIAL_USER';

export const MENU_OPEN = 'MENU_OPEN';
export const MENU_CLOSE = 'MENU_CLOSE';
export const MENU_TOGGLE = 'MENU_TOGGLE';

export const SIGN_SET_AUTHENTICATED = 'SIGN_SET_AUTHENTICATED';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_FACEBOOK = 'SIGN_IN_FACEBOOK';
export const SIGN_IN_APPLE = 'SIGN_IN_APPLE';
export const SIGN_IN_GOOGLE = 'SIGN_IN_GOOGLE';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_FACEBOOK = 'SIGN_UP_FACEBOOK';
export const SIGN_UP_APPLE = 'SIGN_UP_APPLE';
export const SIGN_UP_GOOGLE = 'SIGN_UP_GOOGLE';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN_MODAL_SHOW = 'SIGN_IN_MODAL_SHOW';
export const SIGN_IN_MODAL_HIDE = 'SIGN_IN_MODAL_HIDE';

export const FAVORITE_ADD_COMBINE = 'FAVORITE_ADD_COMBINE';
export const FAVORITE_ADD_PRODUCT = 'FAVORITE_ADD_PRODUCT';
export const FAVORITE_DELETE_COMBINE = 'FAVORITE_DELETE_COMBINE';
export const FAVORITE_DELETE_PRODUCT = 'FAVORITE_DELETE_PRODUCT';

export const CART_ADD_COMBINE = 'CART_ADD_COMBINE';
export const CART_ADD_PRODUCT = 'CART_ADD_PRODUCT';
export const CART_DELETE = 'CART_DELETE';
export const CART_EMPTY_ORDERED = 'CART_EMPTY_ORDERED';
export const CART_DELETE_COMBINE = 'CART_DELETE_COMBINE';
export const CART_DELETE_PRODUCT = 'CART_DELETE_PRODUCT';
export const CART_UPDATE_AMOUNT = 'CART_UPDATE_AMOUNT';
export const CART_UPDATE_PRODUCT_PRICE = 'CART_UPDATE_PRODUCT_PRICE';
export const CART_UPDATE_PRODUCTS = 'CART_UPDATE_PRODUCTS';
export const CART_UPDATE_COMBINES = 'CART_UPDATE_COMBINES';
export const CART_STOCK_CHECK = 'CART_STOCK_CHECK';
export const CART_CLEAR_ALERTS = 'CART_CLEAR_ALERTS';
export const CART_UPDATE_CHECKED = 'CART_UPDATE_CHECKED';
export const CART_UPDATE_DELETED = 'CART_UPDATE_DELETED';
export const CART_CLEAR_DELETED = 'CART_CLEAR_DELETED';
export const CART_CLEAR = 'CART_CLEAR';
export const COUPON_SET = 'COUPON_SET';
export const COUPON_CLEAR = 'COUPON_CLEAR';

export const LATEST_VISIT_ADD_COMBINE = 'LATEST_VISIT_ADD_COMBINE';
export const LATEST_VISIT_ADD_PRODUCT = 'LATEST_VISIT_ADD_PRODUCT';
export const LATEST_VISIT_DELETE = 'LATEST_VISIT_DELETE';
export const LATEST_VISIT_DELETE_COMBINE = 'LATEST_VISIT_DELETE_COMBINE';
export const LATEST_VISIT_DELETE_PRODUCT = 'LATEST_VISIT_DELETE_PRODUCT';
export const LATEST_VISIT_CLEAN = 'LATEST_VISIT_CLEAN';
export const LATEST_VISIT_CLEAN_COMBINE = 'LATEST_VISIT_CLEAN_COMBINE';
export const LATEST_VISIT_CLEAN_PRODUCT = 'LATEST_VISIT_CLEAN_PRODUCT';

export const NOTIFICATION_PUSH = 'NOTIFICATION_PUSH';
export const NOTIFICATION_CLEAR_ONE = 'NOTIFICATION_CLEAR_ONE';
export const NOTIFICATION_CLEAR_ALL = 'NOTIFICATION_CLEAR_ALL';

export const COOKIE_WARNING_ACCEPT = 'COOKIE_WARNING_ACCEPT';
