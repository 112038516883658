import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaShoppingBag } from 'react-icons/fa';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useHistory, Link } from 'react-router-dom';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap-v5';
// import ReactPixel from "react-facebook-pixel";
// import ReactGA from "react-ga";

import { cartProductCreate, notificationPush, signInModalShow } from '../../services/store/actions';
import { Product, ProductVariant } from '../../services/Types';
import {
  selectAuthenticated,
  selectColors,
  selectFavoriteProduct,
  selectSizes,
} from '../../services/store/selectors';
import { favoriteProductCreateSrv, favoriteProductDeleteSrv } from '../../services/Favorite';
import * as RouteGenerator from '../../services/route/RouteGenerator';
import { imageUrl } from '../../services/Image';
import { useWindowSize } from '../../services/util/WindowSize';
import Currency from '../format/Currency';
import Counter from '../input/Counter';
import Percent from '../format/Percent';
import { DetailMore } from '../search/DetailMore';
import './ProductDetail.scss';
import Title from '../format/Title';
import ImageList from '../search/ImageList';

interface ProductDetailProps {
  product: Product;
  colorId?: string;
  layout: 'page' | 'modal';
}

export default function ProductDetail({ product, colorId, layout }: ProductDetailProps) {
  const { productId, variants } = product;

  const dispatch = useDispatch();
  const device = useWindowSize();
  const history = useHistory();

  const authenticated = useSelector(selectAuthenticated);
  const favorites = useSelector(selectFavoriteProduct);
  const favorite = favorites && favorites.includes(productId);

  const colors = useSelector(selectColors);
  const sizes = useSelector(selectSizes);

  const [colorIds, setColorIds] = useState<string[]>([]);
  const [selectedColorId, setSelectedColorId] = useState<string>();
  const [selectedSizeId, setSelectedSizeId] = useState<string>();
  const [selectedAmount, setSelectedAmount] = useState(1);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    const colorIds = product.images.reduce((colorList: string[], image) => {
      if (!colorList.includes(image.colorId)) {
        colorList.push(image.colorId);
      }
      return colorList;
    }, []);
    setColorIds(colorIds);
    if (colorId) {
      setSelectedColorId(colorId);
      setSelectedSizeId(undefined);
    } else {
      setSelectedColorId(colorIds[0]);
    }
  }, [colorId]);

  const handleFavorite = async () => {
    if (!authenticated) {
      dispatch(signInModalShow());
    } else if (favorite) {
      favoriteProductDeleteSrv(productId);
    } else {
      favoriteProductCreateSrv(productId);
    }
  };

  const handleAddToBasket = () => {
    if (selectedSizeId && selectedColorId) {
      dispatch(
        cartProductCreate({
          productId,
          amount: selectedAmount,
          sizeId: selectedSizeId,
          colorId: selectedColorId,
          priceOriginal: variant.priceOriginal,
          priceFinal: variant.priceFinal,
          discount: 0,
        }),
      );

      dispatch(
        notificationPush({
          type: 'success',
          text: 'Ürün sepetinize eklendi.',
        }),
      );

      // ReactPixel.pageView(); // For tracking page view
      // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
      // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
      // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
      // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.

      // ReactGA.event(history.pathname + history.search);
      // ReactGa.ga('send', 'event', {
      //   eventCategory: 'Web Vitals',
      //   eventAction: name,
      //   eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      //   eventLabel: id, // id unique to current page load
      //   nonInteraction: true, // avoids affecting bounce rate
      // });

      history.push('/sepetim');
    } else {
      setIsTouched(true);
    }
  };

  const handleClose = () => {
    history.goBack();
  };

  const color = colors.find((color) => color.colorId === selectedColorId);
  const size = sizes.find((size) => size.sizeId === selectedSizeId);

  let variantsFiltered = [...variants];
  if (selectedColorId) {
    variantsFiltered = variantsFiltered.filter((variant) => variant.colorId === selectedColorId);
  }
  if (selectedSizeId) {
    variantsFiltered = variantsFiltered.filter((variant) => variant.sizeId === selectedSizeId);
  }

  let variant: ProductVariant;
  if (variantsFiltered.length >= 1) {
    variant = variantsFiltered[0];
  } else {
    variant = variants[0];
  }

  const imagesColor = product.images.filter((image) => image.colorId === selectedColorId);

  const detailMore = (
    <div className="product-detail-more">
      <h2>Ürün Açıklaması</h2>
      <ul>
        <li>
          15 gün içinde ücretsiz iade. Detaylı bilgi için
          <span> </span>
          <Link to="/yardim/iade">tıklayın</Link>.
        </li>
        <li>Ürün fiyatları mağaza tarafından belirlenmektedir.</li>
        {product.merchant ? (
          <li>
            Bu ürün
            <span> </span>
            <Link to={`/magaza/${product.merchant.slug}`}>{product.merchant.title}</Link>
            <span> </span>
            tarafından gönderilecektir.
          </li>
        ) : null}
      </ul>
      {product.detail ? (
        <>
          <h2>Mağaza Açıklaması</h2>
          <DetailMore detail={product.detail} />
        </>
      ) : null}
    </div>
  );

  const detail = (
    <>
      <div className="product-detail-wrapper">
        <div className="product-image-wrapper">
          {device.isDesktop ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`favorite-product-${productId}`}>
                  {favorite ? 'Favorilerden Çıkar' : 'Favorilere Ekle'}
                </Tooltip>
              }
            >
              <div
                className={`product-favorite ${favorite ? 'product-favorite-active' : ''}`}
                role="presentation"
                onClick={handleFavorite}
              >
                {favorite ? <AiFillHeart /> : <AiOutlineHeart />}
              </div>
            </OverlayTrigger>
          ) : (
            <div
              className={`product-favorite ${favorite ? 'product-favorite-active' : ''}`}
              role="presentation"
              onClick={handleFavorite}
            >
              {favorite ? <AiFillHeart /> : <AiOutlineHeart />}
            </div>
          )}

          <ImageList images={imagesColor} />
        </div>
        <div className="product-detail">
          <h2 className="product-brand">
            <Link to={`/marka/${product.brand.slug}`}>{product.brand.title}</Link>
          </h2>
          <h1 className="product-title">
            <Title text={product.title} />
            <span> </span>
            {color?.title} {size?.title}
          </h1>
          {product.merchant ? (
            <p>
              Mağaza:
              <span> </span>
              <Link to={`/magaza/${product.merchant.slug}`}>{product.merchant.title}</Link>
            </p>
          ) : null}

          {/*
          {product.rate >= 1 ? (
            <div className="product-rate">
              <Rate rate={product.rate} /> (4 değerlendirme)
            </div>
          ) : null}
          */}

          <div className="product-price">
            <span className="product-discount">
              <Percent original={variant.priceOriginal} final={variant.priceFinal} /> İndirim
            </span>
            <span> </span>
            <span className="product-price-initial">
              <Currency price={variant.priceOriginal} />
            </span>
            <span> </span>
            <span className="product-price-final">
              <Currency price={variant.priceFinal} />
            </span>
            <span style={{ flex: 1 }}> </span>
          </div>

          <div className="product-option-title">
            <strong>Renk: </strong>
            {color?.title}
          </div>

          {colorIds.length > 1 ? (
            <div className="product-variant">
              <ul>
                {colorIds.map((colorId) => {
                  const color = colors.find((color) => color.colorId === colorId);
                  const image = product.images.find((image) => image.colorId === colorId);

                  if (image && color) {
                    if (layout === 'modal') {
                      return (
                        <li
                          key={colorId}
                          role="presentation"
                          className={selectedColorId === colorId ? 'product-variant-active' : ''}
                          onClick={() => {
                            setSelectedColorId(colorId);
                            setSelectedSizeId(undefined);
                          }}
                        >
                          <div>
                            <img src={imageUrl(image.image.path, 'option')} alt="" />
                          </div>
                        </li>
                      );
                    }

                    return (
                      <li
                        key={colorId}
                        role="presentation"
                        className={selectedColorId === colorId ? 'product-variant-active' : ''}
                      >
                        <div>
                          <Link
                            to={RouteGenerator.productDetail(
                              productId,
                              product.slug,
                              color.colorId,
                              color.slug,
                              product.brand.brandId,
                              product.brand.slug,
                            )}
                          >
                            <img src={imageUrl(image.image.path, 'cart')} alt="" />
                          </Link>
                        </div>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          ) : null}

          {isTouched && !selectedColorId ? (
            <div className="product-warning">Lütfen renk seçin.</div>
          ) : null}

          <div className="product-option-title">
            <strong>Beden: </strong>
            {size?.title}
          </div>

          {product.variants.length > 1 ? (
            <div className="product-size">
              <ul>
                {product.variants
                  .filter((variant) => variant.color.colorId === selectedColorId)
                  .map((variant) => {
                    return (
                      <li
                        key={variant.size.sizeId}
                        role="presentation"
                        className={
                          selectedSizeId === variant.size.sizeId ? 'product-size-active' : ''
                        }
                      >
                        <button
                          type="button"
                          className={`btn ${
                            variant.size.sizeId === selectedSizeId
                              ? 'btn-size'
                              : 'btn-size-secondary'
                          }`}
                          onClick={() => setSelectedSizeId(variant.size.sizeId)}
                          disabled={variant.stock === 0}
                        >
                          {variant.size.title}
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </div>
          ) : null}

          {isTouched && !selectedSizeId ? (
            <div className="product-warning">Lütfen beden seçin.</div>
          ) : null}

          {device.isDesktop ? (
            <>
              <div className="product-option-title">
                <strong>Adet: </strong>
              </div>
              <div className="product-add-to-cart">
                <Counter
                  min={1}
                  max={variant?.stock}
                  value={selectedAmount}
                  onChange={(val) => {
                    let value = val;

                    // @ts-ignore
                    if (value > variant.stock) {
                      value = variant?.stock;
                    }

                    if (value < 1) {
                      value = 1;
                    }

                    setSelectedAmount(value);
                  }}
                />
                <button
                  className="btn btn-kombin"
                  type="button"
                  onClick={() => handleAddToBasket()}
                >
                  <FaShoppingBag />
                  <span> </span>
                  Sepetime Ekle
                </button>
              </div>
            </>
          ) : null}

          {variant && variant?.stock < 5 ? (
            <div className="product-out-of-stock-warning">
              Acele edin. Son {variant?.stock} ürün.
            </div>
          ) : null}

          {isTouched && !selectedAmount ? (
            <div className="product-warning">Lütfen adet girin.</div>
          ) : null}
        </div>
      </div>

      {detailMore}
    </>
  );

  return (
    <>
      {device.isDesktop ? (
        <>{detail}</>
      ) : (
        <Modal show onHide={handleClose} fullscreen>
          <Modal.Body className="modal-product">{detail}</Modal.Body>
          <Modal.Footer className="modal-product-footer">
            {history.length > 2 ? (
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
            ) : null}
            <span style={{ flex: 1 }} />

            <Counter
              min={1}
              max={variant?.stock}
              value={selectedAmount}
              onChange={(val) => {
                let value = val;

                // @ts-ignore
                if (value > variant.stock) {
                  value = variant?.stock;
                }

                if (value < 1) {
                  value = 1;
                }

                setSelectedAmount(value);
              }}
            />
            <button className="btn btn-kombin" type="button" onClick={() => handleAddToBasket()}>
              <FaShoppingBag />
              <span> </span>
              Sepetime Ekle
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
