import { Action, Brand } from '../../Types';
import { LOAD_INITIAL } from '../actionTypes';

const initialState: Brand[] = [];

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case LOAD_INITIAL:
      return [...action.payload.brands];
    default:
      return state;
  }
}
