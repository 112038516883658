import React from 'react';
import PropTypes from 'prop-types';

const formatter = new Intl.NumberFormat('tr-TR', {
  style: 'currency',
  currency: 'TRY',
  minimumFractionDigits: 2,
});

interface CurrencyProps {
  price: number;
}

export default function Currency({ price }: CurrencyProps) {
  const priceFormatted = formatter.format(price);

  return <>{priceFormatted}</>;
}

Currency.propTypes = {
  price: PropTypes.number,
};

Currency.defaultProps = {
  price: null,
};
