import { v4 as uuidv4 } from 'uuid';
import { Action, CartItem, Product, CartUpdateAlert, Combine, CartProduct } from '../../Types';
import {
  CART_ADD_COMBINE,
  CART_ADD_PRODUCT,
  CART_DELETE,
  CART_DELETE_COMBINE,
  CART_DELETE_PRODUCT,
  CART_UPDATE_AMOUNT,
  CART_UPDATE_CHECKED,
  CART_UPDATE_DELETED,
  CART_EMPTY_ORDERED,
  CART_CLEAR_DELETED,
  CART_CLEAR,
  COUPON_SET,
  COUPON_CLEAR,
  CART_UPDATE_PRODUCTS,
  CART_CLEAR_ALERTS,
  CART_UPDATE_COMBINES,
  CART_STOCK_CHECK,
} from '../actionTypes';

type Cart = {
  items: CartItem[];
  coupon: string;
  alerts: CartUpdateAlert[];
};

type CartItemsAsProduct = {
  uuid: string;
  combineItemUuid?: string;
  amount: number;
  product: CartProduct;
  productId: string;
  colorId: string;
  sizeId: string;
};

type AmountAndStock = {
  productIndex: number;
  variantIndex: number;
  cartAmount: number;
  stock: number;
  productItemUuid?: string;
  combineItemUuids: string[];
};

const items = localStorage.getItem('items');
const coupon = localStorage.getItem('coupon');

const initialState: Cart = {
  items: items ? JSON.parse(items) : [],
  coupon: coupon ? JSON.parse(coupon) : '',
  alerts: [],
};

export default function reducer(state = initialState, { type, payload }: Action) {
  let items: CartItem[];

  switch (type) {
    case CART_ADD_COMBINE:
      items = [
        {
          type: 'combine',
          uuid: uuidv4(),
          amount: payload.amount,
          priceOriginal: payload.priceOriginal,
          priceFinal: payload.priceFinal,
          checked: true,
          deleted: false,
          combine: {
            combineId: payload.combineId,
            combineVariantId: payload.combineVariantId,
            products: payload.products,
          },
          discount: payload.discount,
        },
        ...state.items,
      ];

      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
      };

    case CART_ADD_PRODUCT:
      const itemNew = state.items.find(
        (item) =>
          item.type === 'product' &&
          item.product &&
          item.product.productId === payload.productId &&
          item.product.colorId === payload.colorId &&
          item.product.sizeId === payload.sizeId,
      );

      if (itemNew) {
        items = [
          ...state.items.map((item) => {
            if (
              item.type === 'product' &&
              item.product &&
              item.product.productId === payload.productId &&
              item.product.colorId === payload.colorId &&
              item.product.sizeId === payload.sizeId
            ) {
              return {
                ...item,
                amount: item.amount + payload.amount,
                checked: true,
                deleted: false,
              };
            }

            return item;
          }),
        ];
      } else {
        items = [
          {
            type: 'product',
            uuid: uuidv4(),
            amount: payload.amount,
            checked: true,
            deleted: false,
            priceOriginal: payload.priceOriginal,
            priceFinal: payload.priceFinal,
            product: {
              productId: payload.productId,
              colorId: payload.colorId,
              sizeId: payload.sizeId,
            },
            discount: payload.discount,
          },
          ...state.items,
        ];
      }

      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
      };

    case CART_UPDATE_AMOUNT:
      items = [...state.items];
      items[items.findIndex((cart) => cart.uuid === payload.uuid)].amount = payload.amount;

      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
      };

    case CART_UPDATE_CHECKED:
      items = [...state.items];
      items[items.findIndex((cart) => cart.uuid === payload.uuid)].checked = payload.checked;

      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
      };

    case CART_UPDATE_DELETED:
      items = [...state.items];
      items[items.findIndex((cart) => cart.uuid === payload.uuid)].deleted = payload.deleted;

      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
      };

    case CART_DELETE:
    case CART_DELETE_COMBINE:
    case CART_DELETE_PRODUCT:
      items = state.items.filter((cart) => cart.uuid !== payload.uuid);

      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
      };
    case CART_EMPTY_ORDERED:
      items = state.items.filter((cart) => !(!cart.deleted && cart.checked));
      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
      };
    case COUPON_SET:
      localStorage.setItem('coupon', JSON.stringify(payload.coupon));

      return {
        ...state,
        coupon: payload.coupon,
      };

    case COUPON_CLEAR:
      items = [...state.items];
      localStorage.removeItem('coupon');

      return {
        ...state,
        items,
        coupon: '',
      };

    case CART_CLEAR_DELETED:
      items = state.items.filter((cart) => !cart.deleted);

      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
      };

    case CART_CLEAR:
      localStorage.setItem('items', JSON.stringify([]));

      return {
        ...state,
        items: [],
      };

    case CART_UPDATE_PRODUCTS: {
      const prevItems = [...state.items];
      const alerts = [...state.alerts];
      items = [...state.items];

      const products: Product[] = payload.products;
      const filteredProducts = products.filter(
        (product) => product.isActive && product.isApproved && product.isActiveMerchant,
      );

      // eslint-disable-next-line no-restricted-syntax
      for (const item of prevItems.filter((item) => item.type === 'product')) {
        const productIndex = filteredProducts.findIndex(
          (product: any) => product.productId === item.product!.productId,
        );

        if (productIndex === -1) {
          // remove from cart if product does not exist anymore
          items = items.filter((cart) => cart.uuid !== item.uuid);
          alerts.push({
            type: 'product-not-for-sale-anymore',
            product: products.find((product: any) => product.productId === item.product!.productId),
            previousItem: { ...item },
          });
          // eslint-disable-next-line no-continue
          continue;
        }

        const variantIndex = filteredProducts[productIndex].variants.findIndex(
          (variant: any) =>
            variant.sizeId === item.product!.sizeId && variant.colorId === item.product!.colorId,
        );

        if (variantIndex === -1) {
          // remove from cart if product variant does not exist anymore
          items = items.filter((cart) => cart.uuid !== item.uuid);
          alerts.push({
            type: 'product-variant-not-for-sale-anymore',
            product: products.find((product: any) => product.productId === item.product!.productId),
            previousItem: { ...item },
          });
          // eslint-disable-next-line no-continue
          continue;
        }

        // uncheck item if it was archived and checked
        if (filteredProducts[productIndex].isArchived && item.checked) {
          items[items.findIndex((cart) => cart.uuid === item.uuid)].checked = false;
          alerts.push({
            type: 'product-is-archived',
            product: filteredProducts[productIndex],
            previousItem: { ...item },
          });
        }

        // check stock is not 0, detailed stock check is done in CART_STOCK_CHECK
        const stock = filteredProducts[productIndex].variants[variantIndex].stock;
        if (stock === 0) {
          items = items.filter((cart) => cart.uuid !== item.uuid);
          alerts.push({
            type: 'product-out-of-stock',
            product: filteredProducts[productIndex],
            previousItem: { ...item },
          });
          // eslint-disable-next-line no-continue
          continue;
        }

        // update price
        const priceOriginal = filteredProducts[productIndex].variants[variantIndex].priceOriginal;
        const priceFinal = filteredProducts[productIndex].variants[variantIndex].priceFinal;
        if (item.priceFinal !== priceFinal) {
          alerts.push({
            type: 'price-is-changed',
            product: filteredProducts[productIndex],
            previousItem: { ...item },
          });
        }
        items[items.findIndex((cart) => cart.uuid === item.uuid)].priceOriginal = priceOriginal;
        items[items.findIndex((cart) => cart.uuid === item.uuid)].priceFinal = priceFinal;
      }

      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
        alerts,
      };
    }

    case CART_UPDATE_COMBINES: {
      const prevItems = [...state.items];
      const alerts = [...state.alerts];
      items = [...state.items];

      const products: Product[] = payload.products;
      const combines: Combine[] = payload.combines;

      const filteredProducts = products.filter(
        (product) => product.isActive && product.isApproved && product.isActiveMerchant,
      );
      const filteredCombines = combines.filter(
        (combine) => combine.isActive && combine.isApproved && combine.isActiveMerchant,
      );

      // eslint-disable-next-line no-restricted-syntax
      for (const item of prevItems.filter((item) => item.type === 'combine')) {
        // check combine itself before combine products. If it fails checks no need to check its products, just continue to next combine
        const combineIndex = filteredCombines.findIndex(
          (combine: any) => combine.combineId === item.combine!.combineId,
        );

        if (combineIndex === -1) {
          // remove from cart if combine does not exist anymore
          items = items.filter((cart) => cart.uuid !== item.uuid);
          alerts.push({
            type: 'combine-not-for-sale-anymore',
            combine: combines.find((combine: any) => combine.combineId === item.combine!.combineId),
            previousItem: { ...item },
          });
          // eslint-disable-next-line no-continue
          continue;
        }

        const combineVariantIndex = filteredCombines[combineIndex].variants.findIndex(
          (variant: any) => variant.combineVariantId === item.combine!.combineVariantId,
        );

        if (combineVariantIndex === -1) {
          // remove from cart if combine variant does not exist anymore
          items = items.filter((cart) => cart.uuid !== item.uuid);
          alerts.push({
            type: 'combine-variant-not-for-sale-anymore',
            combine: combines.find((combine: any) => combine.combineId === item.combine!.combineId),
            previousItem: { ...item },
          });
          // eslint-disable-next-line no-continue
          continue;
        }

        // remove combine if it was archived
        if (filteredCombines[combineIndex].isArchived) {
          // remove from cart if combine is archived
          items = items.filter((cart) => cart.uuid !== item.uuid);
          alerts.push({
            type: 'combine-is-archived',
            combine: combines.find((combine: any) => combine.combineId === item.combine!.combineId),
            previousItem: { ...item },
          });
          // eslint-disable-next-line no-continue
          continue;
        }

        // check each product of the combine
        let combineProductFailedCheck = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const combineProduct of item.combine!.products) {
          if (!combineProductFailedCheck) {
            const productIndex = filteredProducts.findIndex(
              (product: any) => product.productId === combineProduct.productId,
            );

            if (productIndex === -1) {
              // remove combine from cart if combine product does not exist anymore
              items = items.filter((cart) => cart.uuid !== item.uuid);
              alerts.push({
                type: 'combine-product-not-for-sale-anymore',
                product: products.find(
                  (product: any) => product.productId === combineProduct.productId,
                ),
                combine: combines.find(
                  (combine: any) => combine.combineId === item.combine!.combineId,
                ),
                previousItem: { ...item },
              });
              combineProductFailedCheck = true;
              // eslint-disable-next-line no-continue
              continue;
            }

            const variantIndex = filteredProducts[productIndex].variants.findIndex(
              (variant: any) =>
                variant.sizeId === combineProduct.sizeId &&
                variant.colorId === combineProduct.colorId,
            );

            if (variantIndex === -1) {
              // remove combine from cart if combine product variant does not exist anymore
              items = items.filter((cart) => cart.uuid !== item.uuid);
              alerts.push({
                type: 'combine-product-variant-not-for-sale-anymore',
                product: products.find(
                  (product: any) => product.productId === combineProduct.productId,
                ),
                combine: combines.find(
                  (combine: any) => combine.combineId === item.combine!.combineId,
                ),
                previousItem: { ...item },
              });
              combineProductFailedCheck = true;
              // eslint-disable-next-line no-continue
              continue;
            }

            // remove combine from cart if combine product was archived
            if (filteredProducts[productIndex].isArchived) {
              // remove combine from cart if combine product is archived
              items = items.filter((cart) => cart.uuid !== item.uuid);
              alerts.push({
                type: 'combine-product-is-archived',
                product: products.find(
                  (product: any) => product.productId === combineProduct.productId,
                ),
                combine: combines.find(
                  (combine: any) => combine.combineId === item.combine!.combineId,
                ),
                previousItem: { ...item },
              });
              combineProductFailedCheck = true;
              // eslint-disable-next-line no-continue
              continue;
            }

            // remove combine from cart if combine product has 0 stock
            const stock = filteredProducts[productIndex].variants[variantIndex].stock;
            if (stock === 0) {
              // combine product variant has 0 stock, therefore remove combine from cart
              items = items.filter((cart) => cart.uuid !== item.uuid);
              alerts.push({
                type: 'combine-product-out-of-stock',
                product: products.find(
                  (product: any) => product.productId === combineProduct.productId,
                ),
                combine: combines.find(
                  (combine: any) => combine.combineId === item.combine!.combineId,
                ),
                previousItem: { ...item },
              });
              combineProductFailedCheck = true;
              // eslint-disable-next-line no-continue
              continue;
            }
          }
        }

        if (combineProductFailedCheck) {
          /* Combine item already removed, no need to update price */

          // eslint-disable-next-line no-continue
          continue;
        }

        // update combine price
        let priceOriginal = 0;
        let priceFinal = 0;
        item.combine!.products.forEach((cartCombineProduct) => {
          const variant = products
            .find((product) => product.productId === cartCombineProduct.productId)
            ?.variants.find((variant) => {
              return (
                variant.color.colorId === cartCombineProduct.colorId &&
                variant.size.sizeId === cartCombineProduct.sizeId
              );
            });
          if (variant) {
            priceOriginal += variant.priceOriginal;
            priceFinal += variant.priceFinal;
          }
        });
        if (item.priceFinal !== priceFinal) {
          alerts.push({
            type: 'combine-price-is-changed',
            combine: combines.find((combine: any) => combine.combineId === item.combine!.combineId),
            previousItem: { ...item },
          });
        }
        items[items.findIndex((cart) => cart.uuid === item.uuid)].priceOriginal = priceOriginal;
        items[items.findIndex((cart) => cart.uuid === item.uuid)].priceFinal = priceFinal;
      }

      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
        alerts,
      };
    }

    case CART_STOCK_CHECK: {
      const alerts = [...state.alerts];
      items = [...state.items];

      const products: Product[] = payload.products;
      const combines: Combine[] = payload.combines;

      const cartItemsAsProducts = items
        .filter((item) => !item.deleted && item.checked)
        .reduce<CartItemsAsProduct[]>((cartItems, item) => {
          if (item.type === 'product') {
            cartItems.push({
              uuid: item.uuid,
              amount: item.amount,
              product: item.product!,
              productId: item.product!.productId,
              colorId: item.product!.colorId,
              sizeId: item.product!.sizeId,
            });
          }
          if (item.type === 'combine') {
            item.combine!.products.forEach((product) => {
              cartItems.push({
                combineItemUuid: item.uuid,
                uuid: `${item.uuid}-${product.productId}-${product.colorId}-${product.sizeId}`,
                amount: item.amount,
                product,
                productId: product.productId,
                colorId: product.colorId,
                sizeId: product.sizeId,
              });
            });
          }
          return cartItems;
        }, []);

      const amountAndStock: AmountAndStock[] = [];
      cartItemsAsProducts.forEach((cartItem) => {
        const productIndex = products.findIndex(
          (product: any) => product.productId === cartItem.productId,
        );

        const variantIndex = products[productIndex].variants.findIndex(
          (variant: any) =>
            variant.sizeId === cartItem.sizeId && variant.colorId === cartItem.colorId,
        );

        const amountAndStockIndex = amountAndStock.findIndex(
          (item) => item.productIndex === productIndex && item.variantIndex === variantIndex,
        );

        if (amountAndStockIndex === -1) {
          // create new entry in array
          amountAndStock.push({
            productIndex,
            variantIndex,
            cartAmount: cartItem.amount,
            stock: products[productIndex].variants[variantIndex].stock,
            combineItemUuids: cartItem.combineItemUuid ? [cartItem.combineItemUuid] : [],
            productItemUuid: cartItem.combineItemUuid ? undefined : cartItem.uuid,
          });
        } else {
          // edit current entry in array
          const currentAmount = amountAndStock[amountAndStockIndex].cartAmount;
          amountAndStock[amountAndStockIndex].cartAmount = currentAmount + cartItem.amount;
          if (cartItem.combineItemUuid) {
            amountAndStock[amountAndStockIndex].combineItemUuids = [
              ...amountAndStock[amountAndStockIndex].combineItemUuids,
              cartItem.combineItemUuid,
            ];
          } else {
            amountAndStock[amountAndStockIndex].productItemUuid = cartItem.uuid;
          }
        }
      });

      amountAndStock
        .filter((itemAmountStock) => itemAmountStock.cartAmount > itemAmountStock.stock)
        .forEach((itemAmountStock) => {
          let difference = itemAmountStock.cartAmount - itemAmountStock.stock;
          if (difference <= 0) {
            // stock is larger than or equal to total cart amount,
            // no need for any changes related to the stock of this specific product variant
            return;
          }

          const standaloneProductAmount = itemAmountStock.productItemUuid
            ? items[items.findIndex((cart) => cart.uuid === itemAmountStock.productItemUuid)].amount
            : 0;

          if (difference < standaloneProductAmount) {
            // only reduce standalone (i.e. not in a combine) product amount
            items[items.findIndex((cart) => cart.uuid === itemAmountStock.productItemUuid)].amount =
              standaloneProductAmount - difference;
            alerts.push({
              type: 'amount-is-reduced',
              product: products.find(
                (product: any) =>
                  product.productId ===
                  items[items.findIndex((cart) => cart.uuid === itemAmountStock.productItemUuid)]
                    .product?.productId,
              ),
              previousItem: {
                ...items[items.findIndex((cart) => cart.uuid === itemAmountStock.productItemUuid)],
              },
            });
          } else if (difference === standaloneProductAmount) {
            // remove standalone product
            alerts.push({
              type: 'amount-is-reduced',
              product: products.find(
                (product: any) =>
                  product.productId ===
                  items[items.findIndex((cart) => cart.uuid === itemAmountStock.productItemUuid)]
                    .product?.productId,
              ),
              previousItem: {
                ...items[items.findIndex((cart) => cart.uuid === itemAmountStock.productItemUuid)],
              },
            });
            items = items.filter((cart) => cart.uuid !== itemAmountStock.productItemUuid);
          } else {
            // remove standalone product and some combines as needed
            if (itemAmountStock.productItemUuid && standaloneProductAmount > 0) {
              alerts.push({
                type: 'amount-is-reduced',
                product: products.find(
                  (product: any) =>
                    product.productId ===
                    items[items.findIndex((cart) => cart.uuid === itemAmountStock.productItemUuid)]
                      .product?.productId,
                ),
                previousItem: {
                  ...items[
                    items.findIndex((cart) => cart.uuid === itemAmountStock.productItemUuid)
                  ],
                },
              });
              items = items.filter((cart) => cart.uuid !== itemAmountStock.productItemUuid);
              difference -= standaloneProductAmount;
            }

            let combineIndex = 0;

            while (difference > 0) {
              const item =
                items[
                  items.findIndex(
                    // eslint-disable-next-line no-loop-func
                    (cart) => cart.uuid === itemAmountStock.combineItemUuids[combineIndex],
                  )
                ];
              if (!item) {
                // combine was already removed from card due to another product stock limit
                difference--;
                combineIndex++;
                // eslint-disable-next-line no-continue
                continue;
              }
              alerts.push({
                type: 'combine-product-out-of-stock',
                combine: combines.find(
                  // eslint-disable-next-line no-loop-func
                  (combine) => combine.combineId === item.combine?.combineId,
                ),
                previousItem: {
                  ...items[
                    items.findIndex(
                      // eslint-disable-next-line no-loop-func
                      (cart) => cart.uuid === itemAmountStock.combineItemUuids[combineIndex],
                    )
                  ],
                },
              });
              items = items.filter(
                // eslint-disable-next-line no-loop-func
                (cart) => cart.uuid !== itemAmountStock.combineItemUuids[combineIndex],
              );
              difference--;
              combineIndex++;
            }
          }
        });

      localStorage.setItem('items', JSON.stringify(items));

      return {
        ...state,
        items,
        alerts,
      };
    }

    case CART_CLEAR_ALERTS:
      return {
        ...state,
        alerts: [],
      };

    default:
      return state;
  }
}
