import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CombineListHorizontal from './CombineListHorizontal';
import { selectLatestVisitCombineIds } from '../../services/store/selectors';
import { latestVisitCombineClean } from '../../services/store/actions';
import { loadCombines } from '../../services/Combine';
import './CombineViewed.scss';
import BlockSkeleton from '../skeleton/Block';

interface CombineViewedProps {}

export default function CombineViewed({}: CombineViewedProps) {
  const dispatch = useDispatch();
  const latestVisitCombineIds = useSelector(selectLatestVisitCombineIds);

  const handleClean = () => {
    dispatch(latestVisitCombineClean());
  };

  const [combines, setCombines] = useState([]);

  useEffect(() => {
    if (latestVisitCombineIds.length > 0) {
      loadCombines({
        filters: {
          combineIds: ['0', ...latestVisitCombineIds],
          isActive: true,
          isApproved: true,
          isArchived: false,
          isInStock: true,
          isActiveMerchant: true,
        },
        options: {
          limit: 11,
        },
      }).then((combines) => {
        if (combines) {
          setCombines(combines);
        }
      });
    } else {
      setCombines([]);
    }
  }, [latestVisitCombineIds]);

  if (!latestVisitCombineIds || latestVisitCombineIds.length === 0) {
    return <></>;
  }

  if (!combines || combines.length === 0) {
    return (
      <div className="combine-viewed">
        <h4>EN SON İNCELEDİĞİNİZ KOMBİNLER</h4>
        <BlockSkeleton height={347} />
      </div>
    );
  }

  return (
    <div className="combine-viewed">
      <h4>EN SON İNCELEDİĞİNİZ KOMBİNLER</h4>
      <button
        type="button"
        className="btn btn-sm btn-light btn-viewed-clean"
        onClick={() => handleClean()}
      >
        Temizle
      </button>
      <CombineListHorizontal combines={combines} />
    </div>
  );
}
