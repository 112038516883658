import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { breadcrumbClear } from '../services/store/actions';
import ProductViewed from '../components/product/ProductViewed';
import CombineViewed from '../components/combine/CombineViewed';
import './Home.scss';
import Config from "../services/Config";

export default function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(breadcrumbClear());
  }, []);

  const links = [
    {
      text: 'Tüm Ürünler',
      link: '/urunler',
      img: '/img/home/G_TumUrunler.png',
    },
    {
      text: 'En Yeniler',
      link: '/urunler?sirala=add-date',
      img: '/img/home/G_EnYeniler.png',
    },
    {
      text: 'Hu Moda',
      link: '/marka/humoda',
      img: '/img/home/G_HuModa.jpg',
    },
    {
      text: 'EDWOX',
      link: '/magaza/edwox',
      img: '/img/home/G_Edwox.jpg',
    },
    {
      text: 'Pers Prens',
      link: '/magaza/persprens',
      img: '/img/home/G_PersPrens.jpg',
    },
    {
      text: '50 TL Altı Ürünler',
      link: '/urunler?fiyat=0,50',
      img: '/img/home/G_50TlUrunler.png',
    },
    // {
    //   text: '100 TL Altı Kombinler',
    //   link: '/kombin?fiyat=0,100',
    //   img: '/img/home/G_100TlUrunler.png',
    // },
    // {
    //   text: 'Sana Özel',
    //   link: '/urunler?tag=sana-ozel',
    //   img: '/img/home/G_SanaOzel.png',
    // },
    // {
    //   text: 'Adidas',
    //   link: '/marka/adidas',
    //   img: '/img/home/G_Adidas.png',
    // },
    // {
    //   text: 'Network',
    //   link: '/marka/network',
    //   img: '/img/home/G_NetWork.png',
    // },
    {
      text: 'En Çok Satanlar',
      link: '/urunler?sirala=sold',
      img: '/img/home/G_EnCokSatanlar.png',
    },
    {
      text: 'Yüksek Puanlılar',
      link: '/urunler?sirala=rate',
      img: '/img/home/G_YuksekPuanlilar.png',
    },
    {
      text: 'dACollection',
      link: '/magaza/da-collection',
      img: '/img/home/G_DaCollection.jpg',
    },
    {
      text: 'Terapi Men',
      link: '/marka/terapi-men',
      img: '/img/home/G_TerapiMen.jpg',
    },
    // {
    //   text: 'Favorilerim',
    //   link: '/hesabim/favorilerim',
    //   img: '/img/home/G_FavoriUrunler.png',
    // },
    {
      text: 'Ücretsiz Deneme',
      link: '/ucretsiz-deneme',
      img: '/img/home/G_UcretsizDeneme.png',
    },
    {
      text: 'Ücretsiz Kargo',
      link: '/ucretsiz-kargo',
      img: '/img/home/G_UcretsizKargo.png',
    },
  ];

  const canonicalUrl = `${Config.uriWeb}/`;

  return (
    <>
      <Helmet>
        <title>Kampanyalar</title>
        <meta name="canonical" content={canonicalUrl} />
      </Helmet>

      <div className="home-brands">
        {links.map((link) => (
          <Link key={link.link} to={link.link}>
            <img src={link.img} alt={link.text} />
          </Link>
        ))}
      </div>

      {/*
      <div className="home-banners">
        <Link to="/kadin">
          <img src="/img/product/KadinUrunleri.jpg" alt="Kadın Ürünleri" width={1200} height={800} />
        </Link>
        <Link to="/kombin/kadin/tumu">
          <img src="/img/product/KadinKombinleri.jpg" alt="Kadın Kombinleri" width={1200} height={800} />
        </Link>
      </div>
      */}

      <div className="home-banners">
        <Link to="/erkek">
          <img src="/img/product/ErkekUrunleri.jpg" alt="Erkek Ürünleri" width={1200} height={800} />
        </Link>
        <Link to="/kombin/erkek/tumu">
          <img src="/img/product/ErkekKombinleri.jpg" alt="Erkek Kombinleri" width={1200} height={800} />
        </Link>
      </div>

      <ProductViewed />
      <CombineViewed />
    </>
  );
}
