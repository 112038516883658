import { Action, Breadcrumb } from '../../Types';
import { BREADCRUMB_CLEAR, BREADCRUMB_PUSH, BREADCRUMB_SET } from '../actionTypes';

const initialState: Breadcrumb[] = [];

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case BREADCRUMB_SET:
      return [...action.payload];
    case BREADCRUMB_PUSH:
      return [...state, action.payload];
    case BREADCRUMB_CLEAR:
      return [];
    default:
      return state;
  }
}
