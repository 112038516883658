import React, { useEffect, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Alert, Button, Modal } from 'react-bootstrap-v5';
import ReCAPTCHA from 'react-google-recaptcha';
import isEmail from 'validator/lib/isEmail';
import { useDispatch } from 'react-redux';
import { FaCheckCircle } from 'react-icons/fa';

import config from '../../services/Config';
import { notificationPush } from '../../services/store/actions';

interface MarketingEmailFormProps {
  target: 'footer' | 'popup' | 'gardirobum' | 'sanal-deneme-kabini' | 'free-ecommerse';
  onHide?: () => void;
}

const MarketingEmailForm = ({ target, onHide }: MarketingEmailFormProps) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>('');
  const [robotCheckModalShow, setRobotCheckModalShow] = useState<boolean>(false);
  const [showInvalidEmail, setShowInvalidEmail] = useState<boolean>(false);
  const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
  const [renderModal, setRenderModal] = useState<boolean>(false);
  const [displayedEmail, setDisplayedEmail] = useState('');

  const onChangeCaptcha = async (value: string | null) => {
    // console.log('Captcha value:', value);
    await handleSubmitMutation(value);
  };

  const [marketingEmailMutation, marketingEmailMutationResult] = useMutation(gql`
    mutation marketingEmailSubmit($email: String, $target: String, $recaptchaToken: String) {
      marketingEmailSubmit(email: $email, target: $target, recaptchaToken: $recaptchaToken) {
        status
        message
      }
    }
  `);

  useEffect(() => {
    if (marketingEmailMutationResult?.data?.marketingEmailSubmit) {
      if (marketingEmailMutationResult?.data?.marketingEmailSubmit?.status === true) {
        localStorage.setItem('marketingEmailSubmitted', 'true');
        setEmailSuccess(true);
        // reset form
        setEmail('');
        setShowInvalidEmail(false);
      } else {
        dispatch(
          notificationPush({
            type: 'danger',
            text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
          }),
        );
      }
    }
  }, [marketingEmailMutationResult?.data?.marketingEmailSubmit]);

  const [isValid, setIsValid] = useState({
    email: isEmail(email),
  });

  const checkValid = () => {
    setIsValid({
      email: isEmail(email),
    });
  };

  // check validity on changes
  useEffect(() => {
    checkValid();
  }, [email]);

  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    const allValid = Object.values(isValid).every((item) => item === true);

    if (!allValid) {
      setShowInvalidEmail(true);
      return;
    }

    setDisplayedEmail(email);
    setRobotCheckModalShow(true);
  };

  const handleSubmitMutation = async (recaptchaToken: string | null) => {
    try {
      await marketingEmailMutation({
        variables: {
          email,
          recaptchaToken,
          target,
        },
      });
    } catch (e) {
      dispatch(
        notificationPush({
          type: 'danger',
          text: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
        }),
      );
    }
  };

  const handleHideModal = () => {
    if (onHide) {
      onHide();
    }
    setDisplayedEmail('');
    setRobotCheckModalShow(false);
    setEmailSuccess(false);
  };

  return (
    <>
      <form action="" onSubmit={handleSubmitForm}>
        <div className="input-group mb-3 has-validation">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-posta adresiniz"
            type="email"
            name="email"
            className={`form-control ${!isValid.email && showInvalidEmail ? 'is-invalid' : ''}`}
            onFocus={() => setRenderModal(true)}
          />
          <button type="button" className="btn btn-kombin" onClick={handleSubmitForm}>
            Kayıt Ol
          </button>
          <div className="invalid-feedback mb-1">
            {email?.length > 0
              ? 'Lütfen geçerli bir e-posta adresi girin.'
              : 'Lütfen e-posta adresinizi girin.'}
          </div>
        </div>
      </form>
      {renderModal ? (
        <Modal
          animation
          centered
          size="lg"
          fullscreen="md-down"
          show={robotCheckModalShow}
          onHide={handleHideModal}
        >
          <Modal.Header>
            <Modal.Title>Kataloğumuza Kayıt Olun</Modal.Title>
            <Button variant="close" onClick={handleHideModal} />
          </Modal.Header>
          <Modal.Body>
            <p>Kaydolmak için lütfen robot olmadıgınızı kanıtlayın:</p>
            <p>
              E-posta: <strong>{displayedEmail}</strong>
            </p>
            <div className="col-12 col-sm-7 col-md-9 col-lg-6">
              {emailSuccess ? (
                <Alert variant="success" className="mt-2 d-flex align-items-center">
                  <FaCheckCircle color="rgb(15,81,50)" size={18} className="me-2" />
                  <span>Kataloğumuza kayıt oldunuz! Tesekkür ederiz.</span>
                </Alert>
              ) : (
                <ReCAPTCHA
                  sitekey={config.recaptchaSiteKey}
                  onChange={onChangeCaptcha}
                  size="normal"
                  hl="tr"
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleHideModal}>
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export default MarketingEmailForm;
