import { Action, Size } from '../../Types';
import { LOAD_INITIAL } from '../actionTypes';

const initialState: Size[] = [];

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case LOAD_INITIAL:
      return [...action.payload.sizes];
    default:
      return state;
  }
}
