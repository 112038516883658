import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Highlight } from '../format/Highlight';
import { usePrevious } from '../../services/util/UsePrevious';
import { RootState } from '../../services/store/store';
import { Combine as ICombine, Product as IProduct } from '../../services/Types';
import { loadCombines } from '../../services/Combine';
import { loadProducts } from '../../services/Product';
import Combine from '../combine/Combine';
import Product from '../product/Product';
import Error from './Error';
import Loading from './Loading';
import './HeaderSearchDesktop.scss';

type HeaderSearchMoreProps = {
  keyword: string;
  setKeyword: any;
  setSearchActive: any;
};

export default function HeaderSearchDesktop({
  keyword,
  setKeyword,
  setSearchActive,
}: HeaderSearchMoreProps) {
  const styles = useSelector((state: RootState) => state.style);
  const brands = useSelector((state: RootState) => state.brand);
  const { categories } = useSelector((state: RootState) => state.category);

  const keywordLowercase = keyword.toLowerCase();
  const stylesFiltered = styles.filter((style) =>
    style.title.toLowerCase().includes(keywordLowercase),
  );
  const brandsFiltered = brands.filter((brands) =>
    brands.title.toLowerCase().includes(keywordLowercase),
  );
  const categoriesFiltered = categories.filter((category) =>
    category.title.toLowerCase().includes(keywordLowercase),
  );

  const { pathname } = useLocation();
  const pathnamePrev = usePrevious(pathname);
  useEffect(() => {
    if (pathnamePrev !== undefined && pathnamePrev !== pathname) {
      setKeyword('');
      setSearchActive(false);
    }
  }, [pathname]);

  const [combines, setCombines] = useState<ICombine[]>([]);
  const [loadingCombine, setLoadingCombine] = useState(false);
  const [errorCombine, setErrorCombine] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [errorProduct, setErrorProduct] = useState(false);

  useEffect(() => {
    setCombines([]);
    setProducts([]);

    if (keyword.length < 3) {
      setLoadingCombine(false);
      setLoadingProduct(false);
    } else {
      setLoadingCombine(true);
      setLoadingProduct(true);

      loadCombines({
        filters: {
          keyword,
          isActive: true,
          isApproved: true,
          isArchived: false,
          isInStock: true,
          isActiveMerchant: true,
        },
        options: {
          sortBy: 'title',
          limit: 10,
          offset: 0,
        },
      }).then((combines) => {
        setLoadingCombine(false);
        if (combines) {
          setCombines(combines);
        } else {
          setErrorCombine(true);
        }
      });

      loadProducts({
        filters: {
          keyword,
          isActive: true,
          isApproved: true,
          isArchived: false,
          isInStock: true,
          isActiveMerchant: true,
        },
        options: {
          sortBy: 'title',
          limit: 10,
          offset: 0,
        },
      }).then((products) => {
        setLoadingProduct(false);
        if (products) {
          setProducts(products);
        } else {
          setErrorProduct(true);
        }
      });
    }
  }, [keyword]);

  if (errorCombine || errorProduct) {
    return (
      <div className="header-menu-search-desktop">
        <Error message="İçerik yüklenirken bir sorun oluştu. Lütfen daha sonra tekrar deneyin." />
      </div>
    );
  }

  if (keyword.length < 3) {
    return (
      <div className="header-menu-search-desktop">
        Arama yapmak için 3 veya daha fazla karakter girin.
      </div>
    );
  }

  if (loadingCombine || loadingProduct) {
    return (
      <div className="header-menu-search-desktop">
        <Loading />
      </div>
    );
  }

  if (
    combines.length === 0 &&
    products.length === 0 &&
    stylesFiltered.length === 0 &&
    brandsFiltered.length === 0 &&
    categoriesFiltered.length === 0
  ) {
    return (
      <div className="header-menu-search-desktop">
        Hiç sonuç bulunamadı. Lütfen arama kriterinizi gözden geçirin.
      </div>
    );
  }

  return (
    <div className="header-menu-search-desktop">
      <div className="list-group">
        {combines.length > 0 ? (
          <>
            <div className="list-group-item list-group-item-dark">Kombinler</div>
            {combines.map((combine) => (
              <div className="list-group-item" key={combine.combineId}>
                <Combine combine={combine} layout="horizontal" />
              </div>
            ))}
          </>
        ) : null}

        {products.length > 0 ? (
          <>
            <div className="list-group-item list-group-item-dark">Ürünler</div>
            {products.map((product) => (
              <div className="list-group-item" key={product.productId}>
                <Product product={product} layout="horizontal" showPurchaseOptions={false} />
              </div>
            ))}
          </>
        ) : null}

        {stylesFiltered.length > 0 ? (
          <>
            <div className="list-group-item list-group-item-dark">Stiller</div>
            {stylesFiltered.map((style) => (
              <Link
                key={style.styleId}
                to={`/kombin/erkek/${style.slug}`}
                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
              >
                <span>
                  <Highlight stack={style.title} needle={keyword} />
                </span>
                <span className="badge bg-primary rounded-pill">stil</span>
              </Link>
            ))}
          </>
        ) : null}

        {categoriesFiltered.length > 0 ? (
          <>
            <div className="list-group-item list-group-item-dark">Kategoriler</div>
            {categoriesFiltered.map((category) => (
              <Link
                key={category.categoryId}
                to={`/kategori/${category.slugFull}`}
                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
              >
                <span>
                  <Highlight stack={category.titleFull} needle={keyword} />
                </span>
                <span className="badge bg-primary rounded-pill">kategori</span>
              </Link>
            ))}
          </>
        ) : null}

        {brandsFiltered.length > 0 ? (
          <>
            <div className="list-group-item list-group-item-dark">Markalar</div>
            {brandsFiltered.map((brand) => (
              <Link
                key={brand.brandId}
                to={`/marka/${brand.slug}`}
                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
              >
                <span>
                  <Highlight stack={brand.title} needle={keyword} />
                </span>
                <span className="badge bg-primary rounded-pill">marka</span>
              </Link>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
}
