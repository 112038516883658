import { gql } from '@apollo/client';
import { client } from './Api';
import { store } from './store/store';
import { loadInitial, loadInitialUser, signSetAuthenticated } from './store/actions';

export const initial = async () => {
  try {
    const result: any = await client.query({
      query: gql`
        query initial {
          brands {
            brandId
            slug
            title
          }
          merchants {
            merchantId
            slug
            title
          }
          colors {
            colorId
            code
            slug
            title
          }
          genders {
            genderId
            title
          }
          departments {
            departmentId
            title
          }
          departmentStyle {
            departmentId
            department {
              title
              slug
            }
            styleId
            style {
              title
              slug
            }
            banner
            content
          }
          departmentCategory {
            departmentId
            department {
              title
              slug
            }
            categoryId
            category {
              title
              slug
            }
            banner
          }
          categories {
            categoryId
            parentId
            title
            slug
          }
          styles {
            styleId
            slug
            title
          }
          sizes {
            sizeId
            title
          }
        }
      `,
    });

    store.dispatch(
      loadInitial({
        genders: result.data.genders,
        departments: result.data.departments,
        departmentStyle: result.data.departmentStyle,
        departmentCategory: result.data.departmentCategory,
        categories: result.data.categories,
        brands: result.data.brands,
        merchants: result.data.merchants,
        styles: result.data.styles,
        colors: result.data.colors,
        sizes: result.data.sizes,
      }),
    );
  } catch (e) {
    // TODO: Handle can't load initial data.
    console.log(`Can't load initial data. `, e);
  }

  // Testing Subscription
  // try {
  //   client
  //     .subscribe({
  //       query: gql`
  //         subscription {
  //           counter {
  //             count
  //           }
  //         }
  //       `,
  //     })
  //     .subscribe((rows) => {
  //       console.log(rows);
  //     });
  // } catch (e) {
  //   // TODO: Handle can't load initial data.
  //   console.log(`Can't load initial user data. `, e);
  // }
};

export const initialUser = async () => {
  if (localStorage.getItem('token')) {
    try {
      const result: any = await client.query({
        query: gql`
          query initialUser {
            me {
              nameFirst
              nameLast
              email
              phone
              dateBirthday
              genderId
              addressDefault {
                zipCode
                location1 {
                  name
                }
              }
              favoritesCombine
              favoritesProduct
            }
          }
        `,
      });

      store.dispatch(
        loadInitialUser({
          user: result.data.me,
          favoritesCombine: result.data.me.favoritesCombine,
          favoritesProduct: result.data.me.favoritesProduct,
        }),
      );

      store.dispatch(signSetAuthenticated(true));
    } catch (e) {
      store.dispatch(signSetAuthenticated(false));

      // TODO: Handle can't load initial data.
      // console.log(`Can't load initial user data. `, e);
    }
  }

  // Testing Subscription
  // try {
  //   client
  //     .subscribe({
  //       query: gql`
  //         subscription {
  //           counter {
  //             count
  //           }
  //         }
  //       `,
  //     })
  //     .subscribe((rows) => {
  //       console.log(rows);
  //     });
  // } catch (e) {
  //   // TODO: Handle can't load initial data.
  //   console.log(`Can't load initial user data. `, e);
  // }
};
