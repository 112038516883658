import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BiChevronRight } from 'react-icons/bi';
import { Breadcrumb as IBreadcrumb } from '../../services/Types';
import { RootState } from '../../services/store/store';
import './Breadcrumb.scss';
import { useWindowSize } from '../../services/util/WindowSize';

export default function Breadcrumb() {
  const device = useWindowSize();
  const breadcrumbs: IBreadcrumb[] = useSelector((store: RootState) => store.breadcrumb);

  if (device.isMobile) {
    return <></>;
  }

  return (
    <>
      {breadcrumbs && breadcrumbs.length > 1 ? (
        <nav className="nav-breadcrumb">
          {breadcrumbs.map((breadcrumb: IBreadcrumb, index) => (
            <React.Fragment key={breadcrumb.link}>
              {index > 0 ? <BiChevronRight /> : null}
              <Link to={breadcrumb.link}>{breadcrumb.text}</Link>
            </React.Fragment>
          ))}
        </nav>
      ) : null}
    </>
  );
}
