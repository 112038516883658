import {
  Brand,
  CartProduct,
  Category,
  Color,
  Combine,
  Department,
  DepartmentCategory,
  DepartmentStyle,
  Gender,
  Merchant,
  Product,
  Size,
  Style,
  User,
  // Vitrine,
} from '../Types';
import {
  BREADCRUMB_CLEAR,
  BREADCRUMB_PUSH,
  BREADCRUMB_SET,
  NOTIFICATION_CLEAR_ALL,
  NOTIFICATION_CLEAR_ONE,
  NOTIFICATION_PUSH,
  SIGN_OUT,
  SIGN_IN,
  SIGN_UP,
  SIGN_SET_AUTHENTICATED,
  SIGN_IN_MODAL_SHOW,
  SIGN_IN_MODAL_HIDE,
  MENU_TOGGLE,
  MENU_CLOSE,
  MENU_OPEN,
  LOAD_INITIAL,
  LOAD_INITIAL_USER,
  FAVORITE_ADD_COMBINE,
  FAVORITE_ADD_PRODUCT,
  FAVORITE_DELETE_PRODUCT,
  FAVORITE_DELETE_COMBINE,
  CART_ADD_COMBINE,
  CART_ADD_PRODUCT,
  CART_DELETE_PRODUCT,
  CART_DELETE_COMBINE,
  CART_DELETE,
  CART_EMPTY_ORDERED,
  CART_UPDATE_AMOUNT,
  CART_UPDATE_CHECKED,
  CART_UPDATE_DELETED,
  CART_CLEAR_DELETED,
  CART_CLEAR,
  COUPON_SET,
  COUPON_CLEAR,
  COOKIE_WARNING_ACCEPT,
  LATEST_VISIT_CLEAN,
  LATEST_VISIT_DELETE_PRODUCT,
  LATEST_VISIT_DELETE_COMBINE,
  LATEST_VISIT_DELETE,
  LATEST_VISIT_ADD_PRODUCT,
  LATEST_VISIT_ADD_COMBINE,
  LATEST_VISIT_CLEAN_PRODUCT,
  LATEST_VISIT_CLEAN_COMBINE,
  CART_UPDATE_PRODUCTS,
  CART_CLEAR_ALERTS,
  CART_UPDATE_COMBINES,
  CART_STOCK_CHECK,
} from './actionTypes';

export const breadcrumbSet = (payload: { text: string; link: string }[]) => ({
  type: BREADCRUMB_SET,
  payload,
});

export const breadcrumbPush = (payload: { text: string; link: string }) => ({
  type: BREADCRUMB_PUSH,
  payload,
});

export const breadcrumbClear = () => ({ type: BREADCRUMB_CLEAR });

export const menuClose = () => ({ type: MENU_CLOSE });
export const menuOpen = () => ({ type: MENU_OPEN });
export const menuToggle = () => ({ type: MENU_TOGGLE });

export const signIn = (token: string) => ({ type: SIGN_IN, payload: { token } });
export const signUp = (token: string) => ({ type: SIGN_UP, payload: { token } });
export const signOut = () => ({ type: SIGN_OUT });
export const signSetAuthenticated = (authenticated: boolean) => ({
  type: SIGN_SET_AUTHENTICATED,
  payload: { authenticated },
});
export const signInModalShow = () => ({
  type: SIGN_IN_MODAL_SHOW,
  payload: { signInModal: true },
});
export const signInModalHide = () => ({
  type: SIGN_IN_MODAL_HIDE,
  payload: { signInModal: false },
});

export const loadInitial = (payload: {
  genders: Gender[];
  departments: Department[];
  departmentStyle: DepartmentStyle[];
  departmentCategory: DepartmentCategory[];
  brands: Brand[];
  categories: Category[];
  colors: Color[];
  merchants: Merchant[];
  styles: Style[];
  sizes: Size[];
  // vitrines: Vitrine[];
}) => ({ type: LOAD_INITIAL, payload });

export const loadInitialUser = (payload: {
  user: User;
  favoritesProduct: string[];
  favoritesCombine: string[];
}) => ({ type: LOAD_INITIAL_USER, payload });

export const notificationClearAll = () => ({ type: NOTIFICATION_CLEAR_ALL });

export const notificationClearOne = (payload: { notificationId: string }) => ({
  type: NOTIFICATION_CLEAR_ONE,
  payload,
});

export const notificationPush = (payload: {
  type: 'primary' | 'success' | 'danger';
  text: string;
}) => ({
  type: NOTIFICATION_PUSH,
  payload,
});

export const favoriteCombineCreate = (payload: { combineId: string }) => ({
  type: FAVORITE_ADD_COMBINE,
  payload,
});

export const favoriteProductCreate = (payload: { productId: string }) => ({
  type: FAVORITE_ADD_PRODUCT,
  payload,
});

export const favoriteCombineDelete = (payload: { combineId: string }) => ({
  type: FAVORITE_DELETE_COMBINE,
  payload,
});

export const favoriteProductDelete = (payload: { productId: string }) => ({
  type: FAVORITE_DELETE_PRODUCT,
  payload,
});

export const cartCombineCreate = (payload: {
  combineId: string;
  combineVariantId: string;
  amount: number;
  products: CartProduct[];
  priceOriginal: number;
  priceFinal: number;
  discount: number;
}) => ({
  type: CART_ADD_COMBINE,
  payload,
});

export const cartProductCreate = (payload: {
  productId: string;
  amount: number;
  sizeId: string;
  colorId: string;
  priceOriginal: number;
  priceFinal: number;
  discount: number;
}) => ({
  type: CART_ADD_PRODUCT,
  payload,
});

export const cartDelete = (payload: { uuid: string }) => ({
  type: CART_DELETE,
  payload,
});

export const cartCombineDelete = (payload: { combineId: string }) => ({
  type: CART_DELETE_COMBINE,
  payload,
});

export const cartProductDelete = (payload: { productId: string }) => ({
  type: CART_DELETE_PRODUCT,
  payload,
});

export const cartEmptyOrdered = () => ({
  type: CART_EMPTY_ORDERED,
});

export const cartUpdateAmount = (payload: { uuid: string; amount: number }) => ({
  type: CART_UPDATE_AMOUNT,
  payload,
});

export const cartUpdateSelected = (payload: { uuid: string; checked: boolean }) => ({
  type: CART_UPDATE_CHECKED,
  payload,
});

export const cartUpdateDeleted = (payload: { uuid: string; deleted: boolean }) => ({
  type: CART_UPDATE_DELETED,
  payload,
});

export const cartClearDeleted = () => ({
  type: CART_CLEAR_DELETED,
});

export const cartClear = () => ({
  type: CART_CLEAR,
});

export const cartUpdateProducts = (payload: { products: Product[] }) => ({
  type: CART_UPDATE_PRODUCTS,
  payload,
});

export const cartUpdateCombines = (payload: { products: Product[]; combines: Combine[] }) => ({
  type: CART_UPDATE_COMBINES,
  payload,
});

export const cartStockCheck = (payload: { products: Product[]; combines: Combine[] }) => ({
  type: CART_STOCK_CHECK,
  payload,
});

export const cartClearAlerts = () => ({
  type: CART_CLEAR_ALERTS,
});

export const couponSet = (payload: { coupon: string }) => ({
  type: COUPON_SET,
  payload,
});

export const couponClear = () => ({
  type: COUPON_CLEAR,
});

export const cookieWarningAccept = () => ({
  type: COOKIE_WARNING_ACCEPT,
});

export const latestVisitCombineCreate = (combineId: string) => ({
  type: LATEST_VISIT_ADD_COMBINE,
  payload: { combineId },
});

export const latestVisitProductCreate = (productId: string) => ({
  type: LATEST_VISIT_ADD_PRODUCT,
  payload: { productId },
});

export const latestVisitDelete = (payload: { uuid: string }) => ({
  type: LATEST_VISIT_DELETE,
  payload,
});

export const latestVisitCombineDelete = (payload: { combineId: string }) => ({
  type: LATEST_VISIT_DELETE_COMBINE,
  payload,
});

export const latestVisitProductDelete = (payload: { productId: string }) => ({
  type: LATEST_VISIT_DELETE_PRODUCT,
  payload,
});

export const latestVisitClean = () => ({
  type: LATEST_VISIT_CLEAN,
});

export const latestVisitProductClean = () => ({
  type: LATEST_VISIT_CLEAN_PRODUCT,
});

export const latestVisitCombineClean = () => ({
  type: LATEST_VISIT_CLEAN_COMBINE,
});
