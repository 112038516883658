import { Action, Vitrine } from '../../Types';
import { LOAD_INITIAL } from '../actionTypes';

const initialState: Vitrine[] = [];

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case LOAD_INITIAL:
      if (action.payload.vitrines) {
        return [...action.payload.vitrines];
      }
      return state;
    default:
      return state;
  }
}
