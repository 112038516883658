import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface BlockSkeletonProps {
  height: number;
}

export default function BlockSkeleton({ height }: BlockSkeletonProps) {
  return (
    <>
      <Skeleton width="100%" height={height} />
    </>
  );
}
