import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap-v5';
import { RootState } from '../services/store/store';
import { signInModalHide } from '../services/store/actions';

type SignInDialogProps = {};

export default function SignInDialog({}: SignInDialogProps) {
  const dispatch = useDispatch();
  const showDialog = useSelector((state: RootState) => state.auth.signInModal);

  const onDialogCancel = () => {
    dispatch(signInModalHide());
  };

  if (!showDialog) {
    return null;
  }

  return (
    <Modal show={showDialog} onHide={onDialogCancel} centered>
      <Modal.Body>
        <p>
          Ürünü favorilere eklemek için giriş yapmanız gerekir. Giriş yapmak için lütfen
          <span> </span>
          <Link to="/giris-yap" onClick={() => onDialogCancel()}>
            tıklayın
          </Link>
          .
        </p>
        <p>
          Henüz üye değil misiniz?
          <span> </span>
          <Link to="/kayit-ol" onClick={() => onDialogCancel()}>
            Kayıt Olun
          </Link>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
          onClick={() => onDialogCancel()}
        >
          Kapat
        </button>
      </Modal.Footer>
    </Modal>
  );
}
