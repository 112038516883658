import React, { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '../../components/layout/Loading';
import { selectAuthenticated } from '../store/selectors';
import Home from '../../containers/Home';

export const Routes = [
  // Home
  {
    path: '/',
    exact: true,
    component: Home,
  },

  // Combine Detail
  {
    path: '/kombin/:combineSlug-:combineId(\\d+)',
    exact: true,
    component: lazy(() => import('../../containers/CombineDetail')),
  },
  {
    path: '/:styleSlug(.+)/:combineSlug(.+)-:combineVariantSlug(.+)-c-:combineId(\\d+)-:combineVariantId(\\d+)',
    exact: true,
    component: lazy(() => import('../../containers/CombineDetail')),
  },

  // Product Detail
  {
    path: '/urun/:productSlug-:productId(\\d+)',
    exact: true,
    component: lazy(() => import('../../containers/ProductDetail')),
  },
  {
    path: '/:brandSlug(.+)/:productSlug(.+)-:colorSlug(.+)-p-:productId(\\d+)-:colorId(\\d+)',
    exact: true,
    component: lazy(() => import('../../containers/ProductDetail')),
  },

  // Combine Categories
  {
    path: '/kombin',
    exact: true,
    component: lazy(() => import('../../containers/Combine')),
  },
  {
    path: '/kombin/:departmentStyle',
    exact: true,
    component: lazy(() => import('../../containers/CombineDepartment')),
  },
  {
    path: '/kombin/:departmentSlug/:styleSlug',
    exact: true,
    component: lazy(() => import('../../containers/Vitrine')),
  },

  // Product Categories
  {
    path: '/kadin',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/erkek',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/cocuk',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/cocuk-kiz',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/cocuk-erkek',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/bebek',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/bebek-kiz',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/bebek-erkek',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/kategori/:slugCategory(.+)',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/kategori',
    component: lazy(() => import('../../containers/Category')),
  },
  {
    path: '/marka/:slugBrand(.+)',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/marka',
    component: lazy(() => import('../../containers/Brand')),
  },
  {
    path: '/stil/:slugStyle(.+)',
    component: lazy(() => import('../../containers/Vitrine')),
  },
  {
    path: '/stil',
    component: lazy(() => import('../../containers/Style')),
  },
  {
    path: '/magaza/:slugMerchant(.+)',
    component: lazy(() => import('../../containers/ProductListMerchant')),
  },
  {
    path: '/magaza',
    component: lazy(() => import('../../containers/Merchant')),
  },
  {
    path: '/urunler',
    component: lazy(() => import('../../containers/ProductList')),
  },

  // Fancy
  {
    path: '/gardirobum',
    exact: true,
    component: lazy(() => import('../../containers/Wardrobe')),
  },
  {
    path: '/sanal-deneme-kabini',
    exact: true,
    component: lazy(() => import('../../containers/VirtualFittingRoom')),
  },

  // Authentication
  {
    path: '/giris-yap',
    exact: true,
    component: lazy(() => import('../../containers/authentication/SignIn')),
  },
  {
    path: '/guvenli-cikis',
    exact: true,
    component: lazy(() => import('../../containers/authentication/SignOut')),
  },
  {
    path: '/kayit-ol',
    exact: true,
    component: lazy(() => import('../../containers/authentication/SignUp')),
  },
  {
    path: '/sifremi-unuttum',
    exact: true,
    component: lazy(() => import('../../containers/authentication/ForgotPassword')),
  },
  {
    path: '/sifremi-unuttum-dogrulama',
    exact: true,
    component: lazy(() => import('../../containers/authentication/ForgotPasswordReset')),
  },

  // Cart
  {
    path: '/sepetim',
    exact: true,
    component: lazy(() => import('../../containers/cart/Cart')),
  },
  {
    path: '/siparis-olustur',
    exact: true,
    component: lazy(() => import('../../containers/order-create/OrderCreate')),
    auth: true,
  },
  {
    path: '/siparis-odeme/:orderNumber',
    exact: true,
    component: lazy(() => import('../../containers/order-payment/OrderPayment')),
    auth: true,
  },
  {
    path: '/siparis-sonuc/:orderNumber',
    exact: true,
    component: lazy(() => import('../../containers/order-result/OrderResult')),
    auth: true,
  },
  {
    path: '/hesabim',
    exact: true,
    component: lazy(() => import('../../containers/account/Account')),
    auth: true,
  },

  // Account
  {
    path: '/hesabim/siparislerim',
    exact: true,
    component: lazy(() => import('../../containers/account/order/Order')),
    auth: true,
  },
  {
    path: '/hesabim/siparislerim/:orderNumber',
    exact: true,
    component: lazy(() => import('../../containers/account/order-detail/OrderDetail')),
    auth: true,
  },
  {
    path: '/hesabim/siparis-iptal/:orderNumber',
    exact: true,
    component: lazy(() => import('../../containers/account/order-cancel/OrderCancel')),
    auth: true,
  },
  {
    path: '/hesabim/favorilerim',
    exact: true,
    component: lazy(() => import('../../containers/account/favorite/Favorite')),
    auth: true,
  },
  {
    path: '/hesabim/bildirimlerim',
    exact: true,
    component: lazy(() => import('../../containers/account/Notification')),
    auth: true,
  },
  {
    path: '/hesabim/degerlendirmelerim',
    exact: true,
    component: lazy(() => import('../../containers/account/Evaluation')),
    auth: true,
  },
  {
    path: '/hesabim/hesap-bilgilerim',
    exact: true,
    component: lazy(() => import('../../containers/account/info/Info')),
    auth: true,
  },
  {
    path: '/hesabim/hesap-bilgilerim/kullanici-bilgilerim',
    exact: true,
    component: lazy(() => import('../../containers/account/info/Info')),
    auth: true,
  },
  {
    path: '/hesabim/hesap-bilgilerim/sifre-degisikligi',
    exact: true,
    component: lazy(() => import('../../containers/account/info/PasswordChange')),
    auth: true,
  },
  {
    path: '/hesabim/hesap-bilgilerim/duyuru-tercihlerim',
    exact: true,
    component: lazy(() => import('../../containers/account/info/NotificationOptions')),
    auth: true,
  },
  {
    path: '/hesabim/adreslerim',
    exact: true,
    component: lazy(() => import('../../containers/account/address/Address')),
    auth: true,
  },
  {
    path: '/hesabim/tercihlerim',
    exact: true,
    component: lazy(() => import('../../containers/account/Option')),
    auth: true,
  },
  {
    path: '/hesabim/indirim-kuponlarim',
    exact: true,
    component: lazy(() => import('../../containers/account/Coupon')),
    auth: true,
  },

  // Static Pages
  {
    path: '/biz-kimiz',
    exact: true,
    component: lazy(() => import('../../containers/static/About')),
  },
  {
    path: '/iletisim',
    exact: true,
    component: lazy(() => import('../../containers/static/Contact')),
  },
  {
    path: '/yardim',
    component: lazy(() => import('../../containers/help/Help')),
  },
  {
    path: '/kolay-iade',
    exact: true,
    component: lazy(() => import('../../containers/static/OrderReturn')),
  },
  {
    path: '/siparis-takibi',
    exact: true,
    component: lazy(() => import('../../containers/static/OrderTrack')),
  },
  {
    path: '/ucretsiz-deneme',
    exact: true,
    component: lazy(() => import('../../containers/static/FreeTryHome')),
  },
  {
    path: '/ucretsiz-kargo',
    exact: true,
    component: lazy(() => import('../../containers/static/FreeCargo')),
  },
  {
    path: '/satis-ortakligi',
    exact: true,
    component: lazy(() => import('../../containers/static/Partner')),
  },
  {
    path: '/ucretsiz-e-ticaret-sitesi',
    exact: true,
    component: lazy(() => import('../../containers/static/PartnerFreeEcommerse')),
  },
  {
    path: '/kvkk',
    exact: true,
    component: lazy(() => import('../../containers/static/Kvkk')),
  },
  {
    path: '/destek',
    exact: true,
    component: lazy(() => import('../../containers/static/Support')),
  },
  {
    path: '/kullanici-sozlesmesi',
    exact: true,
    component: lazy(() => import('../../containers/static/UserAgreement')),
  },
  {
    path: '/gizlilik-politikasi',
    exact: true,
    component: lazy(() => import('../../containers/static/PrivacyPolicy')),
  },
  {
    path: '/on-bilgilendirme-formu',
    exact: true,
    component: lazy(() => import('../../containers/static/PreInformationForm')),
  },
  {
    path: '/mesafeli-satis-sozlesmesi',
    exact: true,
    component: lazy(() => import('../../containers/static/DistancedSalesAgreement')),
  },
  {
    path: '/cerez-politikasi',
    exact: true,
    component: lazy(() => import('../../containers/static/CookiePolicy')),
  },

  // Blog
  {
    path: '/blog',
    exact: true,
    component: lazy(() => import('../../containers/blog/BlogList')),
  },
  {
    path: '/blog/:postSlug(.+)',
    exact: true,
    component: lazy(() => import('../../containers/blog/BlogPost')),
  },
];

export function PrivateRoute({ children, ...rest }: { children: any }) {
  const authenticated = useSelector(selectAuthenticated);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
          <>
            {authenticated === undefined ? (
              <Loading />
            ) : (
              <Redirect
                to={{
                  pathname: '/giris-yap',
                  state: { from: location },
                }}
              />
            )}
          </>
        )
      }
    />
  );
}

export function RouteWithSubRoutes(route: any) {
  const element = (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );

  return <>{route.auth ? <PrivateRoute>{element}</PrivateRoute> : element}</>;
}

export function RouteStatus(props: any) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = props.statusCode;
        }

        return <div>{props.children}</div>;
      }}
    />
  );
}
