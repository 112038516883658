import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import './Counter.scss';

interface CounterProps {
  value: number;
  min: number;
  max: number;
  onChange: any;
}

export default function Counter({ value, min, max, onChange }: CounterProps) {
  return (
    <div className="input-counter input-group">
      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={() => onChange(value - 1)}
        disabled={value <= min}
      >
        <AiOutlineMinus />
      </button>
      <input
        type="number"
        className="form-control"
        value={value}
        onChange={(e) => {
          const val = parseInt(e.target.value, 10);

          if (val < 1) {
            onChange(1);
          } else {
            onChange(val);
          }
        }}
        min={min}
        max={max}
        step={1}
      />
      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={() => onChange(value + 1)}
        disabled={value >= max}
      >
        <AiOutlinePlus />
      </button>
    </div>
  );
}

Counter.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

Counter.defaultProps = {
  onChange: () => {},
  value: 1,
  min: 1,
  max: 100,
};
