import { v4 as uuidv4 } from 'uuid';
import { Action, LatestVisitItem } from '../../Types';
import {
  LATEST_VISIT_ADD_COMBINE,
  LATEST_VISIT_ADD_PRODUCT,
  LATEST_VISIT_CLEAN,
  LATEST_VISIT_CLEAN_COMBINE,
  LATEST_VISIT_CLEAN_PRODUCT,
  LATEST_VISIT_DELETE,
  LATEST_VISIT_DELETE_COMBINE,
  LATEST_VISIT_DELETE_PRODUCT,
} from '../actionTypes';

const latestVisitItems = localStorage.getItem('latestVisitItems');

const initialState: LatestVisitItem[] = latestVisitItems ? JSON.parse(latestVisitItems) : [];

export default function reducer(state = initialState, { type, payload }: Action) {
  let stateNew: LatestVisitItem[];

  switch (type) {
    case LATEST_VISIT_ADD_COMBINE:
      if (state.some((latestVisitItem) => latestVisitItem.combineId === payload.combineId)) {
        return state;
      }

      stateNew = [
        ...state,
        {
          type: 'combine',
          uuid: uuidv4(),
          combineId: payload.combineId,
        },
      ];

      localStorage.setItem('latestVisitItems', JSON.stringify(stateNew));

      return stateNew;

    case LATEST_VISIT_ADD_PRODUCT:
      if (state.some((latestVisitItem) => latestVisitItem.productId === payload.productId)) {
        return state;
      }

      stateNew = [
        ...state,
        {
          type: 'product',
          uuid: uuidv4(),
          productId: payload.productId,
        },
      ];

      localStorage.setItem('latestVisitItems', JSON.stringify(stateNew));

      return stateNew;

    case LATEST_VISIT_DELETE:
    case LATEST_VISIT_DELETE_COMBINE:
    case LATEST_VISIT_DELETE_PRODUCT:
      stateNew = state.filter((latestVisitItem) => latestVisitItem.uuid !== payload.uuid);

      localStorage.setItem('latestVisitItems', JSON.stringify(stateNew));

      return stateNew;

    case LATEST_VISIT_CLEAN:
      localStorage.removeItem('latestVisitItems');

      return [];

    case LATEST_VISIT_CLEAN_COMBINE:
      stateNew = state.filter((latestVisitItem) => latestVisitItem.type !== 'combine');

      localStorage.setItem('latestVisitItems', JSON.stringify(stateNew));

      return stateNew;

    case LATEST_VISIT_CLEAN_PRODUCT:
      stateNew = state.filter((latestVisitItem) => latestVisitItem.type !== 'product');

      localStorage.setItem('latestVisitItems', JSON.stringify(stateNew));

      return stateNew;

    default:
      return state;
  }
}
