import { RootState } from './store';
import {
  Brand,
  CartItem,
  CartUpdateAlert,
  Category,
  Color,
  Department,
  DepartmentCategory,
  DepartmentStyle,
  Merchant,
  Size,
  Style,
  Vitrine,
} from '../Types';

export const selectInitialDataLoading = (store: RootState): boolean => {
  return store.initial.loadingData;
};

export const selectInitialDataLoaded = (store: RootState): boolean => {
  return store.initial.loadedData;
};

export const selectDepartments = (store: RootState): Department[] => {
  return store.department;
};

export const selectDepartmentStyle = (store: RootState): DepartmentStyle[] => {
  return store.departmentStyle;
};

export const selectDepartmentCategory = (store: RootState): DepartmentCategory[] => {
  return store.departmentCategory;
};

export const selectBrands = (store: RootState): Brand[] => {
  return store.brand;
};

export const selectMerchants = (store: RootState): Merchant[] => {
  return store.merchant;
};

export const selectStyles = (store: RootState): Style[] => {
  return store.style;
};

export const selectVitrines = (store: RootState): Vitrine[] => {
  return store.vitrine;
};

export const selectColors = (store: RootState): Color[] => {
  return store.color;
};

export const selectCategories = (store: RootState): Category[] => {
  return store.category.categoriesRaw;
};

export const selectSizes = (store: RootState): Size[] => {
  return store.size;
};

export const selectCartItems = (store: RootState): CartItem[] => {
  const { items }: { items: CartItem[] } = store.cart;
  return items;
};

export const selectCartUpdateAlerts = (store: RootState): CartUpdateAlert[] => {
  const { alerts }: { alerts: CartUpdateAlert[] } = store.cart;
  return alerts;
};

export const selectCartCount = (store: RootState): number => {
  const { items }: { items: CartItem[] } = store.cart;

  const productCount = items
    .filter((item) => item.type === 'product')
    .filter((item) => !item.deleted)
    .filter((item) => item.checked)
    .reduce((amount, item: CartItem) => {
      return amount + item.amount;
    }, 0);

  const combineCount = items
    .filter((item) => item.type === 'combine')
    .filter((item) => !item.deleted)
    .filter((item) => item.checked)
    .reduce((amount, item: CartItem) => {
      return amount + item.amount * item.combine!.products.length;
    }, 0);

  return productCount + combineCount;
};

export const selectCartTotal = (store: RootState): number => {
  const totalFinal = selectCartTotalFinal(store);
  const cargoOriginal = selectCartCargoOriginal();
  const cargoDiscount = selectCartCargoDiscount(store);

  return totalFinal + cargoOriginal + cargoDiscount;
};

export const selectCartTotalFinal = (store: RootState): number => {
  const items = selectCartItemsActive(store);

  return items.reduce((total, item: CartItem) => {
    return total + item.amount * item.priceFinal * (100 - (item.discount ? item.discount : 0)) / 100;
  }, 0);
};

export const selectCartTotalOriginal = (store: RootState): number => {
  const items = selectCartItemsActive(store);

  return items.reduce((total, item: CartItem) => {
    return total + item.amount * item.priceOriginal;
  }, 0);
};

export const selectCartTotalDiscount = (store: RootState): number => {
  const items = selectCartItemsActive(store);

  return (
    -1 *
    items.reduce((total, item: CartItem) => {
      return total + item.amount * (item.priceOriginal - item.priceFinal);
    }, 0)
  );
};

export const selectCartItemsActive = (store: RootState) => {
  const { items }: { items: CartItem[] } = store.cart;

  return items.filter((item) => item.checked && !item.deleted);
};

export const selectCartCargoOriginal = (): number => {
  return 14.5;
};

export const selectCartCargoDiscount = (store: RootState): number => {
  const cartTotalFinal = selectCartTotalFinal(store);
  if (cartTotalFinal > 100) {
    return -14.5;
  }

  return 0;
};

export const selectCartCargoFinal = (store: RootState): number => {
  return selectCartCargoOriginal() + selectCartCargoDiscount(store);
};

export const selectUser = (store: RootState) => {
  return store.user.user;
};

export const selectAuthenticated = (store: RootState) => {
  return store.auth.authenticated;
};

export const selectCookieWarning = (store: RootState) => {
  return store.cookieWarning;
};

export const selectFavoriteCount = (store: RootState) => {
  return store.user.favoritesProduct.length + store.user.favoritesCombine.length;
};

export const selectFavoriteProduct = (store: RootState) => {
  return store.user.favoritesProduct;
};

export const selectFavoriteCombine = (store: RootState) => {
  return store.user.favoritesCombine;
};

export const selectLatestVisitProductIds = (store: RootState) => {
  if (store.latestVisit) {
    return store.latestVisit
      .filter((latestVisitItem) => latestVisitItem.type === 'product')
      .reduce<string[]>((productIds: string[], latestVisitItem) => {
        if (
          latestVisitItem.type === 'product' &&
          latestVisitItem.productId &&
          !productIds.includes(latestVisitItem.productId)
        ) {
          productIds.push(latestVisitItem.productId);
        }
        return productIds;
      }, []);
  }
  return [];
};

export const selectLatestVisitCombineIds = (store: RootState) => {
  if (store.latestVisit) {
    return store.latestVisit
      .filter((latestVisitItem) => latestVisitItem.type === 'combine')
      .reduce<string[]>((combineIds: string[], latestVisitItem) => {
        if (
          latestVisitItem.type === 'combine' &&
          latestVisitItem.combineId &&
          !combineIds.includes(latestVisitItem.combineId)
        ) {
          combineIds.push(latestVisitItem.combineId);
        }
        return combineIds;
      }, []);
  }
  return [];
};
