import { Action } from '../../Types';
import {
  SIGN_IN,
  SIGN_IN_MODAL_HIDE,
  SIGN_IN_MODAL_SHOW,
  SIGN_OUT,
  SIGN_SET_AUTHENTICATED,
  SIGN_UP,
} from '../actionTypes';
import { client } from '../../Api';

type Auth = {
  token: string | null;
  authenticated?: boolean;
  signInModal: boolean;
};

const initialState: Auth = {
  token: localStorage.getItem('token'),
  authenticated: localStorage.getItem('token') !== null ? undefined : false,
  signInModal: false,
};

export default function reducer(state = initialState, { type, payload }: Action) {
  switch (type) {
    case SIGN_IN:
      localStorage.setItem('token', payload.token);

      return {
        ...state,
        token: payload.token,
        authenticated: true,
      };
    case SIGN_SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: payload.authenticated,
      };
    case SIGN_UP:
      localStorage.setItem('token', payload.token);

      return {
        ...state,
        token: payload.token,
        authenticated: true,
      };
    case SIGN_OUT:
      localStorage.removeItem('token');
      client.resetStore();
      return {
        ...state,
        token: null,
        authenticated: false,
      };
    case SIGN_IN_MODAL_SHOW:
      return {
        ...state,
        signInModal: payload.signInModal,
      };
    case SIGN_IN_MODAL_HIDE:
      return {
        ...state,
        signInModal: payload.signInModal,
      };
    default:
      return state;
  }
}
