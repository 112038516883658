import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavbarDesktop.scss';
import { useSelector } from 'react-redux';
import {
  selectCategories,
  selectDepartmentCategory,
  selectDepartmentStyle,
} from '../../services/store/selectors';
import { imageUrl } from '../../services/Image';

export default function Navbar() {
  const [navbar, setNavbar] = useState<string>('');
  const [navbarShow, setNavbarShow] = useState(false);
  const [active, setActive] = useState<string>('');
  const [image, setImage] = useState<string>();
  const location = useLocation();
  const departmentStyle = useSelector(selectDepartmentStyle);
  const departmentCategory = useSelector(selectDepartmentCategory);
  const categories = useSelector(selectCategories);

  // console.log(departmentCategory);

  const handleSetNavbar = (navbarName: string) => {
    // If navbar is already active then switch fast
    setNavbar(navbarName);
    setNavbarShow(true);
  };

  const handleClearNavbar = () => {
    setNavbarShow(false);
    setNavbar('');
  };

  const { pathname } = useLocation();
  useEffect(() => {
    handleClearNavbar();
  }, [pathname]);

  useEffect(() => {
    if (navbarShow) {
      const scrollbarWidth = window.innerWidth - document.body.clientWidth;

      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }
  }, [navbarShow]);

  useEffect(() => {
    let active = '';

    if (location.pathname.startsWith('/hesabim/favorilerim')) {
      active = 'favorite';
    } else if (location.pathname.startsWith('/hesabim')) {
      active = 'account';
    } else if (
      location.pathname.startsWith('/giris-yap') ||
      location.pathname.startsWith('/kayit-ol') ||
      location.pathname.startsWith('/sifremi-unuttum') ||
      location.pathname.startsWith('/sifremi-unuttum-dogrulama')
    ) {
      active = 'account';
    } else if (location.pathname.startsWith('/kombin')) {
      active = 'combine';
    } else if (location.pathname.startsWith('/kadin')) {
      active = 'women';
    } else if (location.pathname.startsWith('/erkek')) {
      active = 'men';
    } else if (location.pathname.startsWith('/yardim')) {
      active = 'help';
    } else if (location.pathname === '/sepetim') {
      active = 'cart';
    } else if (location.pathname === '/gardirobum') {
      active = 'wardrobe';
    } else if (location.pathname === '/sanal-deneme-kabini') {
      active = 'virtual-fitting-room';
    } else if (location.pathname === '/') {
      active = 'home';
    }

    setActive(active);
  }, [location.pathname]);

  return (
    <>
      <nav
        className={`navigation ${navbarShow ? 'navigation-show' : ''}`}
        onMouseLeave={() => handleClearNavbar()}
      >
        <div className="nav">
          <div
            className={`
              ${navbar === 'home' ? 'nav-hover' : ''} 
              ${active === 'home' ? 'nav-active' : ''}
            `}
            onMouseEnter={() => handleClearNavbar()}
          >
            <Link to="/">KAMPANYALAR</Link>
          </div>
          <div
            className={`
              ${navbar === 'combine' ? 'nav-hover' : ''} 
              ${active === 'combine' ? 'nav-active' : ''}
            `}
            onMouseEnter={() => handleSetNavbar('combine')}
          >
            <Link to="/kombin/erkek">KOMBİNLER</Link>
          </div>
          {/*
          <div
            className={`
              ${navbar === 'women' ? 'nav-hover' : ''} 
              ${active === 'women' ? 'nav-active' : ''}
            `}
            onMouseEnter={() => handleSetNavbar('women')}
          >
            <Link to="/kadin">KADIN</Link>
          </div>
          */}
          <div
            className={`
              ${navbar === 'men' ? 'nav-hover' : ''} 
              ${active === 'men' ? 'nav-active' : ''}
            `}
            onMouseEnter={() => handleSetNavbar('men')}
          >
            <Link to="/erkek">ERKEK</Link>
          </div>
          <span style={{ flex: 1 }} />
          <div
            className={`${active === 'wardrobe' ? 'nav-active' : ''}`}
            onMouseEnter={() => handleClearNavbar()}
          >
            <Link to="/gardirobum">GARDIROBUM</Link>
          </div>
          <div
            className={`${active === 'virtual-fitting-room' ? 'nav-active' : ''}`}
            onMouseEnter={() => handleClearNavbar()}
          >
            <Link to="/sanal-deneme-kabini">SANAL DENEME KABİNİ</Link>
          </div>
        </div>

        <div className={`nav-more ${navbar ? 'nav-more-show' : ''}`}>
          {navbar === 'combine' ? (
            <div className="nav-combine row">
              {/*
              <div className="col-4">
                <h4>
                  <Link
                    to="/kombin/kadin"
                    onMouseEnter={() => setImage('/img/product/KadinKombinleri.jpg')}
                  >
                    Kadın Giyim Stilleri
                  </Link>
                </h4>
                <div className="row">
                  {departmentStyle
                    .filter((departmentStyle) => departmentStyle.departmentId === '1')
                    .map((departmentStyle) => (
                      <div
                        className="col-6"
                        key={`${departmentStyle.departmentId}-${departmentStyle.styleId}`}
                      >
                        <Link
                          to={`/kombin/${departmentStyle.department.slug}/${departmentStyle.style.slug}`}
                          onMouseEnter={() => setImage(imageUrl(departmentStyle.banner, 'style'))}
                        >
                          {departmentStyle.style.title}
                        </Link>
                      </div>
                    ))}
                </div>
                <div>
                  <Link
                    to="/kombin/kadin/tumu"
                    onMouseEnter={() => setImage('/img/product/KadinKombinleri.jpg')}
                  >
                    Tüm Kadın Kombinleri
                  </Link>
                </div>
              </div>
              */}
              <div className="col-8">
                <h4>
                  <Link
                    to="/kombin/erkek"
                    onMouseEnter={() => setImage('/img/product/ErkekKombinleri.jpg')}
                  >
                    Erkek Giyim Stilleri
                  </Link>
                </h4>
                <div className="row">
                  {departmentStyle
                    .filter((departmentStyle) => departmentStyle.departmentId === '2')
                    .map((departmentStyle) => (
                      <div
                        className="col-6"
                        key={`${departmentStyle.departmentId}-${departmentStyle.styleId}`}
                      >
                        <Link
                          to={`/kombin/${departmentStyle.department.slug}/${departmentStyle.style.slug}`}
                          onMouseEnter={() => setImage(imageUrl(departmentStyle.banner, 'style'))}
                        >
                          {departmentStyle.style.title}
                        </Link>
                      </div>
                    ))}
                </div>
                <div>
                  <Link
                    to="/kombin/erkek/tumu"
                    onMouseEnter={() => setImage('/img/product/ErkekKombinleri.jpg')}
                  >
                    Tüm Erkek Kombinleri
                  </Link>
                </div>
              </div>
              <div className="navigation-image col-4">
                {image ? <img src={image} width="100%" alt="" /> : null}
              </div>
            </div>
          ) : null}

          {navbar === 'women' ? (
            <>
              <div className="nav-category row">
                {departmentCategory
                  .filter((departmentC) => departmentC.departmentId === '1')
                  .slice(0, 4)
                  .map((departmentC) => {
                    const categoryT = categories.find(
                      (category) => category.categoryId === departmentC.categoryId,
                    );

                    if (!categoryT) {
                      return <React.Fragment key={departmentC.categoryId} />;
                    }

                    // console.log(departmentC);
                    // console.log(categoryT);

                    return (
                      <div className="col-2" key={categoryT.categoryId}>
                        <h4>
                          <Link to={`/kadin?kategori=${categoryT.categoryId}`} onClick={() => handleClearNavbar()}>{categoryT.title}</Link>
                        </h4>
                        <ul>
                          {categories
                            .filter((category) => category.parentId === departmentC.categoryId)
                            .slice(0, 12)
                            .map((category) => (
                              <li key={category.categoryId}>
                                <Link to={`/kadin?kategori=${category.categoryId}`} onClick={() => handleClearNavbar()}>{category.title}</Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : null}

          {navbar === 'men' ? (
            <>
              <div className="nav-category row">
                {departmentCategory
                  .filter((departmentC) => departmentC.departmentId === '2')
                  .slice(0, 4)
                  .map((departmentC) => {
                    const categoryT = categories.find(
                      (category) => category.categoryId === departmentC.categoryId,
                    );

                    if (!categoryT) {
                      return <React.Fragment key={departmentC.categoryId} />;
                    }

                    return (
                      <div className="col-2" key={categoryT.categoryId}>
                        <h4>
                          <Link to={`/erkek?kategori=${categoryT.categoryId}`} onClick={() => handleClearNavbar()}>{categoryT.title}</Link>
                        </h4>
                        <ul>
                          {categories
                            .filter((category) => category.parentId === departmentC.categoryId)
                            .slice(0, 12)
                            .map((category) => (
                              <li key={category.categoryId}>
                                <Link to={`/erkek?kategori=${category.categoryId}`} onClick={() => handleClearNavbar()}>{category.title}</Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : null}

          {navbar === 'kid' ? (
            <>
              <div className="nav-category row">
                <div className="col-2">
                  <h4>
                    <Link to="/cocuk-kiz">Kız Çocuk</Link>
                  </h4>
                  <ul>
                    {departmentCategory
                      .filter((departmentC) => departmentC.departmentId === '3')
                      .slice(0, 10)
                      .map((departmentC) => {
                        const categoryT = categories.find(
                          (category) => category.categoryId === departmentC.categoryId,
                        );

                        if (!categoryT) {
                          return <React.Fragment key={departmentC.categoryId} />;
                        }

                        return (
                          <li key={categoryT.categoryId}>
                            <Link to={`/kategori/${categoryT.slugFull}`}>{categoryT.title}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="col-2">
                  <h4>
                    <Link to="/cocuk-erkek">Erkek Çocuk</Link>
                  </h4>
                  <ul>
                    {departmentCategory
                      .filter((departmentC) => departmentC.departmentId === '4')
                      .slice(0, 10)
                      .map((departmentC) => {
                        const categoryT = categories.find(
                          (category) => category.categoryId === departmentC.categoryId,
                        );

                        if (!categoryT) {
                          return <React.Fragment key={departmentC.categoryId} />;
                        }

                        return (
                          <li key={categoryT.categoryId}>
                            <Link to={`/kategori/${categoryT.slugFull}`}>{categoryT.title}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="col-2">
                  <h4>
                    <Link to="/bebek-kiz">Kız Bebek</Link>
                  </h4>
                  <ul>
                    {departmentCategory
                      .filter((departmentC) => departmentC.departmentId === '5')
                      .slice(0, 10)
                      .map((departmentC) => {
                        const categoryT = categories.find(
                          (category) => category.categoryId === departmentC.categoryId,
                        );

                        if (!categoryT) {
                          return <React.Fragment key={departmentC.categoryId} />;
                        }

                        return (
                          <li key={categoryT.categoryId}>
                            <Link to={`/kategori/${categoryT.slugFull}`}>{categoryT.title}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="col-2">
                  <h4>
                    <Link to="/bebek-erkek">Erkek Bebek</Link>
                  </h4>
                  <ul>
                    {departmentCategory
                      .filter((departmentC) => departmentC.departmentId === '6')
                      .slice(0, 10)
                      .map((departmentC) => {
                        const categoryT = categories.find(
                          (category) => category.categoryId === departmentC.categoryId,
                        );

                        if (!categoryT) {
                          return <React.Fragment key={departmentC.categoryId} />;
                        }

                        return (
                          <li key={categoryT.categoryId}>
                            <Link to={`/kategori/${categoryT.slugFull}`}>{categoryT.title}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </nav>
      <div className="navigation-backdrop" role="presentation" />
    </>
  );
}
