import React, { useEffect, useRef } from 'react';
import { Toast } from 'bootstrap';
import { useDispatch } from 'react-redux';
import { notificationClearOne } from '../../services/store/actions';

interface NotificationProps {
  id: string;
  type: 'primary' | 'success' | 'danger';
  text: string;
}

export default function Notification({ type, text, id }: NotificationProps) {
  const dispatch = useDispatch();

  const toastRef = useRef<HTMLDivElement>(null);
  let toastInstance: Toast | null;
  let toast: Element | null = null;
  useEffect(() => {
    if (toastRef.current) {
      toast = toastRef.current;
      toastInstance = Toast.getInstance(toast);
      toast.addEventListener('hidden.bs.toast', () => {
        dispatch(notificationClearOne({ notificationId: id }));
      });
    }
    if (!toastInstance && toast) {
      // initialize and show Toast
      toastInstance = new Toast(toast);
      toastInstance.show();
    }
  });

  return (
    <div
      className={`toast align-items-center text-white bg-${type} border-0`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      ref={toastRef}
    >
      <div className="d-flex">
        <div className="toast-body">{text}</div>
        <button
          type="button"
          className="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        />
      </div>
    </div>
  );
}
