import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import './Search.scss';

const interval = 400;

interface SearchProps {
  value: string;
  placeholder: string;
  onSearch?: any;
  onChange: any;
  onEnter?: any;
  onFocus?: any;
  onBlur?: any;
  showSearchButton?: boolean;
  showClearButton?: boolean;
}

export default function Search({
  value,
  placeholder,
  onSearch,
  onChange,
  onEnter,
  onFocus,
  onBlur,
  showSearchButton,
  showClearButton,
}: SearchProps) {
  const [typing, setTyping] = useState<any>(null);

  return (
    <div className="search input-group">
      <input
        type="search"
        name="search"
        className="form-control"
        placeholder={placeholder}
        value={value}
        autoComplete="off"
        onChange={(e: any) => {
          onChange(e.target.value);

          if (typing) {
            clearTimeout(typing);
          }

          setTyping(
            setTimeout(() => {
              onSearch(e.target.value);
            }, interval),
          );
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onEnter(value);
          }
        }}
        onFocus={() => onFocus()}
        onBlur={() => onBlur()}
      />

      {showSearchButton ? (
        <button className="btn btn-kombin" type="button">
          <AiOutlineSearch />
        </button>
      ) : null}

      {showClearButton && value ? (
        <button
          className="btn btn-outline-secondary"
          type="button"
          disabled={!value}
          onClick={() => {
            onChange('');
            onSearch('');
          }}
        >
          <AiOutlineClose />
        </button>
      ) : null}
    </div>
  );
}

Search.propTypes = {
  onSearch: PropTypes.func,
  onEnter: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  showClearButton: PropTypes.bool,
};

Search.defaultProps = {
  onSearch: () => {},
  onEnter: () => {},
  onFocus: () => {},
  onBlur: () => {},
  placeholder: 'Ara...',
  showClearButton: true,
};
