export function ucWordsLocale(text: string, locale = 'TR') {
  const separateText = text.toLocaleLowerCase(locale).split(' ');

  for (let i = 0; i < separateText.length; i++) {
    separateText[i] =
      separateText[i].charAt(0).toLocaleUpperCase(locale) + separateText[i].substring(1);
  }

  return separateText.join(' ');
}
